// .ConfirmModal {
//   .forgotSign {
//     display: flex;
//     width: 100%;
//     justify-content: center;
//     padding: 0 5px;
//   }
//   .LoadinContainer {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 3rem;
//   }
// }

.SponsorshipCancel {
  .modal-dialog {
    margin: 5rem auto 2rem auto;
  }
  .modal-header {
    position: relative;
  }

  .TitleContent {
    text-align: center;
    margin: 3rem 0 2rem 0;
    width: 100%;
    padding-left: 2rem;
    h4 {
      color: #ccc;
    }
    small {
      color: #5ab1e8;
    }
    img {
      position: absolute;
      top: -50px;
      border-radius: 100%;
      box-shadow: 0 0 3px 1px #5ab1e8;
      background-color: #fff;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
    }
  }
  button {
    border-radius: 30px;
  }
}
