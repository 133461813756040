.wrapper {
  max-width: 1500px;
  padding: 1px 30px 30px 30px;
  margin: auto;
}
.SearchArea {
  background-color: #f3f4f5;

  .SearchableBoxes {
    display: grid;
    grid-template-columns:
      41px minmax(150px, 15%) minmax(120px, 15%) minmax(120px, 15%)
      minmax(120px, 15%) minmax(120px, 15%) minmax(120px, 15%);
    grid-gap: 1rem;
    margin-top: 2rem;
    justify-content: center;
    @media (max-width: 1023px) {
      grid-template-columns:
        minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
        minmax(100px, 1fr);
      > div:nth-child(7) {
        grid-column: 3 / 5;
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;

      > div:nth-child(7) {
        grid-column: 1 / 2;
      }
      > div:nth-child(1) {
        text-align: center;
      }
    }
  }
  input {
    font-size: 0.8rem;
    line-height: 26px;
  }
  h1 {
    color: #1b435d;
    text-align: center;
    margin-top: 36px;
    padding-bottom: 36px;
    margin-bottom: 2px;
    font-size: 2rem;
    border-bottom: 1px solid #d4d3d3;
  }
  .row {
    max-width: 1200px;
    margin: auto;
  }

  .fullMap {
    fill: #5ab1e8;
    margin-top: 15px;
    width: 36px;
  }
  .Loacated {
    .fullMap {
      fill: #d0d0d0;
      .active {
        fill: #5ab1e8;
      }
    }
  }
  label {
    color: rgb(121, 121, 121);
    margin-left: 5px;
  }
}
.filtersSummery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // padding: 0 1rem;
  > div {
    display: flex;
    flex-wrap: wrap;
    border-radius: 31px;
    padding: 2px 5px;

    span {
      font-size: 0.8rem;
      padding: 5px;
      // margin: 0 5px 5px 5px;
      border-radius: 30px;
      // background: #ececec;
      color: rgb(85, 85, 85);
      strong {
        color: #000000;
        margin-right: 5px;
      }
    }
  }
}
.clearBtn {
  margin-right: 1rem;
  font-weight: normal;
  color: #5ab1e8;
  &:hover {
    cursor: pointer;
    color: #4c92be;
  }
}

.searchWrapper {
  display: flex;
  align-items: center;
  position: relative;

  input[type="text"] {
    border-radius: 30px;
    width: 100%;
    padding: 12px 15px;
    border: solid 1px #8fcef5;
    &:focus {
      outline: none;
    }
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  i,
  svg {
    font-size: 1.3rem;
    color: #8fcef5;
  }

  .icon {
    position: relative;

    input[type="text"] {
      padding-right: 40px; /* Adjust to fit icon */
    }

    button {
      right: 10px; /* Adjust based on the size of the icon */
      bottom: 10px;
    }
  }
}
