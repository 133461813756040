.SponsoredStudents {
  max-width: 1024px;
  margin: auto;
  text-align: center;
  > h1 {
    text-align: center;
    font-weight: 300;
    color: #1b435d;
  }
  .LinkLineButton {
    margin-bottom: 5px;
  }
  small {
    span {
      color: #5ab1e8;
    }
  }
}
