.StudentTag {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 1rem 0;
  border-radius: 3px;
  color: inherit;
  flex: 1;
  height: 100%;

  padding: 1rem;
  box-shadow: 0 0 1px 0 #fff inset;
  transition: all 0.3s linear;
  background-color: #fff;
  &:hover {
    text-decoration: none;
    box-shadow: 0 0 1px 0 #333 inset;
  }
  .ProfilePic {
    width: 100%;
    max-width: 200px;
    height: 200px;
    background-color: #333;
    overflow: hidden;
    img {
      width: 100%;
      background-color: #f6f6f6;
      border-radius: 3px;
    }
  }
  .small {
    margin: 1rem;
  }
  .discription {
    color: #a3a3a3;
    padding: 0 1rem;
    text-align: center;
    span {
      color: #268ccc;
    }
  }
  .sponserButton {
    background-color: #268ccc;
    color: #fff;
    position: relative;
    width: 100%;
    max-width: 290px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 30px;
    padding: 8px;
    border-radius: 30px;
    transition: all 0.2s;
    font-size: 1.2rem;
    height: 60px;
    cursor: pointer;
    margin-top: auto;
    i {
      font-style: normal;

      &.off {
        display: none;
      }
    }
    span {
      position: absolute;
      width: 50px;
      height: 50px;
      font-size: 1rem;
      background-color: #2279af;
      border-radius: 30px;
      left: 5px;
      top: 5px;
      line-height: 50px;
      transition: all 0.2s;
      border: solid 1px #fff;
    }
    p {
      opacity: 1;
      top: 110%;

      position: absolute;

      color: #848484;
      font-size: 0.8rem;
      text-align: center;
      width: 100%;
      transition: all 0.2s;
    }
    &:hover {
      background-color: #00af6b;
      font-size: 1rem;
      i {
        font-style: normal;

        &.off {
          display: inline;
          // margin-right: 0.5rem;
        }

        &.on {
          display: none;
        }
      }
      p {
        opacity: 0;
        top: 0;
      }
      span {
        background-color: #fff;
        color: #00af6b;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 30px;
      background-color: transparent;
      left: 0;
      top: 100%;
    }
    .twoLine {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 0.8rem;
      i {
        font-size: 1.1rem;
      }
    }
  }
  .progressBar {
    position: relative;
    width: 200px;
    border-radius: 0;
    margin: 1rem 0;
    .active {
      &::before {
        background-color: #5ab1e8;
      }
    }
    span {
      position: absolute;
      top: 3px;
    }

    span::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #ccc;
      display: block;
      position: relative;
      top: -7px;
      border-radius: 5px;
    }
    .line {
      width: 100%;
      height: 3px;
      background-color: #ccc;
      margin-bottom: 30px;
      border-radius: 3px;
      span {
        background-color: #5ab1e8;
        height: 3px;
        top: 0;
      }
      span::before {
        display: none;
      }
    }
    .g8 {
      left: 0;
    }
    .ol {
      left: 33.33%;
    }
    .al {
      left: 66.99%;
    }
    .ico {
      left: 100%;
    }
  }
  .spBy {
    font-size: 1.2rem;
    color: #ff895d;
    line-height: 2rem;
    i {
      margin-left: 0.5rem;
      color: #7dd565;
      font-size: 1.8rem;
    }
  }
  .profDetail {
    display: flex;
    margin-top: 0.5rem;
    align-content: center;
    align-items: center;
    justify-content: center;
    img {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      margin-right: 0.5rem;
      background-color: #efefef;
      border: solid 1px #efefef;
    }
  }
  .spTag {
    margin-top: auto;
    margin-bottom: 2.5rem;
  }
  .stName {
    font-size: 1.2rem;
    color: #0183b5;
  }
}
