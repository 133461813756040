.footerMenu {
  color: #fff;
  text-transform: lowercase;
  h4 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    a {
      color: #ffffff85;
      &.active.on{
        color: #fff;
      }
    }
  }
}
.inherit{
  color: inherit;
}