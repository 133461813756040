.StudentProfile {
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
  color: #484848;
  h1 {
    font-size: 1.9rem;
    color: #a4a4a4;
    span {
      color: #5ab1e8;
    }
  }
  .download {
    color: #8fcef5;
    font-weight: 600;
    button {
      color: #333;
      font-weight: 400;
      background-color: transparent;
      border: none;
      &:hover {
        color: #000;
      }
    }
  }
  svg {
    fill: #e7e7e7;
    width: 200px;
    margin: auto;
    max-width: 100%;
    path {
      stroke: #c6c6c6;
      stroke-width: 0.1px;
    }
  }
}
.StudentProfileSlider{
  max-width: 100%;
  ul{
    padding: 0;
    .thumb{
      height: 50px;
      margin-right: 2px;
      img{
        object-fit: cover;
      }
    }
  }
}