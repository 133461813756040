.WHoItWOrk {
  padding: 5rem 1rem;
  background-size: cover;
  .HowItBox {
    padding: 0 2rem;
    text-align: center;
    border-right: solid 1px #ccc;
    &.last {
      border: none;
    }
    h3 {
      margin: 1rem 0;
    }
    p {
      font-size: 0.8rem;
      color: #687f91;
    }
  }
}
