.StudentDetail {
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto;
  .results {
    color: #6f6f6f;
    font-size: 13px;
    .ALevel {
      list-style: square outside none;
      padding-left: 1rem;
      li {
        list-style: disc;
        div {
          display: flex;
          justify-content: space-between;
          span {
            margin-right: 1rem;
          }
        }
      }
    }
  }
  > h1 {
    font-size: 30px;
    color: #5ab1e8;
    font-weight: 600;
  }
  .StrongTag {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    align-items: flex-start;
    white-space: pre-line;
    div {
      &.Sponser {
        background-color: rgb(190, 190, 190);
        &:hover {
          background-color: rgb(190, 190, 190);
        }
      }
    }
    .Sponser {
      background-color: #4099ff;
      padding: 0.2rem 1rem 0.2rem 0.2rem;
      color: #fff;
      border-radius: 30px;
      min-width: 150px;
      margin-top: 0.5rem;
      font-size: 0.8rem;
      white-space: nowrap;
      &:hover {
        text-decoration: none;
        background-color: #3081df;
      }

      img {
        border-radius: 30px;
        width: 30px;
        height: 30px;
        margin-right: 0.5rem;
      }
    }
  }
  h2 {
    color: #1b435d;
    font-weight: 300;
    margin: 2rem 0 1rem 0;
  }
  h3 {
    color: #929292;
    font-size: 19px;
    margin-top: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .socialShare {
    margin: 5rem auto;
    display: flex;
    justify-content: center;

    .Profile {
      width: 100px;
    }

    .Profile img {
      width: 100%;
    }

    .socialData h3 {
      margin-top: 0;
      color: #333;
      font-size: 1.5rem;
    }

    .socialData {
      margin-left: 1rem;
    }

    .socialData a {
      width: 60px;
      height: 60px;
      border: solid;
      display: inline-block;
      border-radius: 38px;
      margin-right: 20px;
      @media (max-width: 900px) {
        width: 42px;
        height: 42px;
        font-size: 1.2rem;
        line-height: 40px;
      }
      &.tw {
        transition: all 0.2s linear;
        svg{
          width: 1.6rem;
          path{
            fill: #4099ff;
            transition: all 0.2s linear;
          }
        }
      }
      &.tw:hover {
        background-color: #4099ff;
        svg{
          path{
            fill: #fff;
          }
        }
      }
      &.fb {
        transition: all 0.2s linear;
        svg{
          width: 1rem;
          path{
            fill: #3b5998;
            transition: all 0.2s linear;
          }
        }
      }
      &.fb:hover{
        background-color: #3b5998;
        svg{
          path{
            fill: #fff;
          }
        }
      }
      &.mail {
        color: #5cddac;
      }
    }
    .copy-btn{
      width: 60px;
      height: 60px;
      display: inline-block;
      background-color: transparent;
      border: 3px solid #929292;
      border-radius: 38px;
      margin: 0;
      padding: 0 !important;
      transition: all 0.2s linear;
      transform: translateY(-1px);
      
      svg {
        width: 1.6rem;
        margin-bottom: 2px;
        path{
          fill: #929292;
          transition: all 0.2s linear;
        }
      }
  
      &:hover {
        background-color: #929292;
        svg{
          path{
            fill: #fff;
          }
        }
        text-decoration: none;
      }
  
  }
  }
  .sponsorBtn {
    background-color: #5ab1e8;
    display: flex;
    color: #fff;
    margin: 1rem auto;
    padding: 8px;
    // line-height: 50px;
    max-width: 230px;
    border-radius: 30px;
    align-items: center;
    transition: all 0.2s linear;
    text-align: center;
    // justify-content: center;
    &:hover {
      background-color: #2d7fb3;
      text-decoration: none;
    }
    span {
      background-color: #fff;
      color: #5ab1e8;
      display: block;
      margin-right: 1rem;
      line-height: 40px;
      width: 40px;
      height: 40px;
      border-radius: 30px;
    }
  }
  .buttonCenterd {
    display: flex;
    justify-content: center;
  }
}

.sponser {
  display: inline-flex;
  flex-direction: column;

  &.need {
    button,
    a {
      background-color: #ff895d;
      span {
        color: #ff895d;
      }
      div {
        // justify-content: center;
      }
      &:hover {
        background-color: #fc7848;
      }
    }
  }
  button,
  a {
    background-color: #5ab1e8;
    color: #fff;
    display: flex;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 52px;
    min-width: 210px;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    border: none;
    &:hover {
      text-decoration: none;
      background-color: #44a3de;
    }
    span {
      width: 50px;
      height: 50px;
      display: block;
      background-color: #fff;
      border-radius: 30px;
      text-align: center;
      line-height: 50px;
      color: #44a3de;
      font-weight: 600;
      font-size: 1.2rem;
    }
    div {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
    }
  }

  > span {
    text-align: center;

    display: block;
  }
  .buttonCenterd {
    display: flex;
    justify-content: center;
  }
}
