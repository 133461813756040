.ContactHeader {
  min-height: 40vh;
  padding-top: 12rem;
  padding-bottom: 5rem;
  background-position: top center;
  background-size: cover;
  position: relative;
  h2 {
    font-size: 3rem;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #3336;
    z-index: 1;
  }
  .FundBox {
    position: relative;
    z-index: 5;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    .inputContainer {
      display: flex;
      background-color: #3335;
      padding: 0.5rem;
      border-radius: 3rem;
    }

    .TwoTag span {
      position: absolute;
      left: 15px;
      color: #333;
      top: 13px;
    }

    .inputContainer button {
      margin-bottom: 0;
      margin-left: 0.5rem;
    }

    .TwoTag {
      width: 250px;
      position: relative;
      font-size: 1.2rem;
    }

    .TwoTag span:last-child {
      right: 15px;
      left: auto;
    }

    .TwoTag input {
      padding-left: 2rem !important;
      padding-right: 4rem !important;
      color: #ff895d;
    }
  }
}
