// .ConfirmModal {
//   .forgotSign {
//     display: flex;
//     width: 100%;
//     justify-content: center;
//     padding: 0 5px;
//   }
//   .LoadinContainer {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 3rem;
//   }
// }

.ConfirmModal {
  .modal-dialog {
    margin: 5rem auto 2rem auto;
  }
  .modal-header {
    position: relative;
  }

  .TitleContent {
    text-align: center;
    margin: 3rem 0 2rem 0;
    width: 100%;
    padding-left: 2rem;
    h4 {
      color: #ccc;
    }
    small {
      color: #5ab1e8;
      font-size: 17px;
    }
    img {
      position: absolute;
      top: -50px;
      border-radius: 100%;
      box-shadow: 0 0 3px 1px #5ab1e8;
      background-color: #fff;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
    }
  }
  button {
    border-radius: 30px;
  }

  .sponsorshipAmountHandleArea {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #ff895d;
    .inpBox{
      margin-left: .5rem;
    }
    button {
      margin: 1rem;
      width: 60px;
      height: 40px;
      line-height: 28px;
      padding: 0;
      color: #fff;
      border: none;
      transition: all 0.2s;
      text-align: center;
      font-size: 1rem;
      &:disabled{
        background-color: #e7e6e6 !important;
    color: #b7b7b7;
    cursor: not-allowed;
      }
      &.add{
        background-color: #ff895d;
        &:hover{
          background-color: darken($color: #ff895d, $amount: 10%);
        }
      }
      &.subtract{
        background-color: #5ab1e8;
        &:hover{
          background-color: darken($color: #5ab1e8, $amount: 10%);
        }
      }
    }
  }
}
