.Header {
  padding: 2rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  &.relative {
    position: relative;
    background: #5ab1e8;
  }
  .wrapper {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }
  .toggle {
    display: none;
  }
  @media (max-width: 900px) {
    .Navigation {
      display: none;
      ul {
        position: relative;
        top: 0;
        transform: translate(0);
        a {
          font-weight: 300;
          color: #ccc;
        }
      }
      &.Show {
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 0;
        background-color: #000e;
        padding: 15px 30px;
        z-index: 100;
        border-radius: 10px;
        width: 100%;
        height: 100vh;
        padding-top: 124px;
        text-align: center;
        overflow: auto;
      }
    }
    .toggle {
      display: block;
      color: #fff;
      font-size: 2rem;
      padding: 5px 30px;
      &.Show {
        position: fixed;
        z-index: 10000;
        left: calc(50% - 43px);
      }
    }
  }
}

.AdminHeader {
  background-color: #ffffff;
  padding: 0.5rem 2rem 0.5rem calc(250px + 1rem);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  button {
    color: #333 !important;
    text-transform: uppercase;
  }
}
