.socialBtn {
  display: flex;
  justify-content: space-between;
  > div {
    width: 49%;
    button {
      flex: 1;
      border: none;
      border-radius: 5px;
      width: 100%;
      padding: 10px;
      color: #fff;
      background-color: #f83403;
      transition: all 0.2s;
    
      &:hover {
        opacity: 0.8;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}
