.elf-engage-invite-email {
  margin-bottom: 12%;
}
.elf-engage-invite-email h4 {
  margin-top: 1.2rem;
}
.elf-engage-invite-email h4 {
  font-size: 18px;
    font-weight: 700;
  color: #fff;
}

.elf-engage-invite-email p {
  font-weight: 300;
}

.elf-engage-invite-email .elf-textBox {
  box-shadow: none;
  height: 50px;
  padding-left: 15px;
  font-size: 13pt;
  font-weight: 300;
  width: 55%;
  color: #696969;
  border: solid 1px transparent;
  margin-right: 2px;
  border-radius: 30px;
  margin-right: 15px;
  position: relative;
  top: 2px;
  width: 100%;
}
.elf-engage-invite-email .elf-textBox:focus {
  outline: none;
}
.elf-engage-invite-email {
  .relative {
    flex: 1;
    position: relative;
    padding-right: 1rem;
  }
  .btn-primary {
    border-radius: 30px;
  }
}

.elf-engage-invite-email .btn {
  padding-left: 22px;
  padding-right: 24px;
  height: 50px;
  padding-top: 0px;
  padding-bottom: 2px;
  font-size: 18px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .elf-engage-invite-email .elf-textBox {
    height: 40px;
    padding-left: 15px;
    font-size: 13pt;
    font-weight: 300;
    width: 100%;
  }
  .elf-engage-invite-email .btn {
    margin-top: 10px;
    margin-left: 24%;
  }
}

@media (max-width: 991px) {
  .elf-engage-invite-email .relative {
    width: 100%;
    padding-right: 0;
  }
  .elf-engage-invite-email button.btn.btn-primary.btn-lg {
    width: 100%;
    margin: 1rem 0;
    height: 40px;
  }
}
@media (max-width: 480px) {
  .elf-engage-invite-email .elf-textBox {
    height: 40px;
    padding-left: 15px;
    font-size: 13pt;
    font-weight: 300;
    width: 100%;
    margin-right: 0;
  }

  .elf-engage-invite-email .btn {
    margin-top: 10px;
    margin-left: 17%;
  }
}
