.StudentSearch {
  max-width: 1500px;
  padding: 0 1rem;
  margin: auto;
  .loadMore {
    text-align: center;
    margin-bottom: 4rem;
    small {
      span {
        color: #5ab1e8;
        font-weight: 600;
      }
    }
    .LinkLineButton {
      margin-bottom: 0;
    }
  }
}
.centerItem {
  margin: 2rem auto;
  padding: 3rem 0;
}
.notFound {
  padding: 1rem 2rem;
  background-color: #ffd34180;
  margin: 2rem auto;
  // width: calc(100% - 2rem);
  text-align: center;
  border-radius: 5px;
  color: #806200;
  border: solid 1px #ffd341;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.pagination {
  margin: 2rem auto 4rem auto;
  display: flex;
  list-style: none;
  justify-content: center;
  li {
    a {
      padding: 5px 10px;
      background-color: #efefef;
      margin-left: 1px;
      display: block;
    }
  }
  .selected {
    a {
      background-color: #268ccc;
      border-color: #268ccc;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  .disabled {
    a {
      color: #868686;
      &:hover {
        color: #868686;
        cursor: not-allowed;
      }
    }
  }
  // a {
  //   color: #268ccc;
  // }
}
