@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Fjalla+One&display=swap");
@import "~bootstrap/scss/bootstrap";
$primary-color: #5ab1e8;
$secondary-color: #ff895d;
/* font-family: 'Fjalla One', sans-serif; */
.App {
  /* background-color: #ccc; */
  font-family: "Lato", sans-serif;
}
a {
  color: #8fcef5;
}
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
.modal-title.h4 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.modal-content {
  font-family: "Lato", sans-serif;
}

.inputContainer {
  margin-bottom: 1.5rem;
  position: relative;
  .srv-validation-message {
    bottom: 41px;
    right: 11px;
    top: auto;
  }
  .css-yk16xz-control {
    border: solid 1px #8fcef5;
    border-radius: 30px;
  }

  input[type="text"],
  input[type="date"],
  input[type="password"],
  textarea,
  select {
    border-radius: 30px;
    width: 100%;
    padding: 12px 15px;

    border: solid 1px #8fcef5;
    &:focus {
      outline: none;
    }
  }
  &.sm {
    input[type="text"] {
      padding: 6px 15px;
    }
    &.icon {
      i {
        bottom: 10px;
      }
    }
  }
  &.icon {
    input[type="text"] {
      padding-right: 54px !important;
    }
    position: relative;
    i {
      position: absolute;
      right: 20px;
      bottom: 15px;
      font-size: 1.3rem;
      color: #8fcef5;
    }
  }
  .errorMessage {
    color: $secondary-color;
    position: absolute;
    right: 0;
    font-size: 0.7rem;
    top: 100%;
    // color: #fff;
    border-radius: 10px;
    padding: 1px 10px;
    z-index: 10;
    & + input {
      border-color: $secondary-color;
    }
    & + textarea {
      border-color: $secondary-color;
    }
  }
}
.light-font {
  font-weight: 300;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.srv-validation-message {
  background-color: $secondary-color;
  position: absolute;
  right: 2rem;
  font-size: 0.7rem;
  top: -10px;
  color: #fff;
  border-radius: 10px;
  padding: 1px 10px;
  z-index: 10;
  ~ input,
  ~ textarea,
  ~ .react-datepicker-wrapper input {
    border-color: $secondary-color !important;
    background-color: #ff000012;
    ~ i {
      color: $secondary-color !important;
    }
  }
  ~ .customSelect {
    > div {
      border-color: $secondary-color !important;
      svg {
        color: $secondary-color !important;
      }
      span {
        background-color: $secondary-color !important;
      }
    }
  }
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}
.fade-exit.fade-exit-active {
  display: none;
}
.ScrollTable {
  max-width: 100%;
  overflow: auto;
  table {
    width: 100%;
  }
}
table {
  line-height: 2rem;
  td,
  th {
    padding: 1rem;
  }
  thead {
    background-color: #5ab1e8;
    color: #fff;
  }
  tbody {
    tr:nth-child(2n + 2) {
      background-color: #f9f9f9c4;
    }
  }
}
.ashSection {
  background-color: #fff;
  padding: 1rem;
}
.successmsg {
  text-align: center;
  padding: 2rem 0;
  i {
    display: block;
    font-size: 9rem;
  }
}

.SuccessAlert {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00771e;
  color: #ffffffd1;
  width: 100%;
  height: 100px;
  text-align: center;
  z-index: 1000;
  line-height: 100px;
  font-weight: 600;
  box-shadow: -2px -5px 16px #000;
}
.ErrorAlert {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #732000;
  color: #ffffffd1;
  width: 100%;
  height: 100px;
  text-align: center;
  z-index: 1000;
  line-height: 100px;
  font-weight: 600;
  box-shadow: -2px -5px 16px #000;
}
.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover {
    background-color: darken($primary-color, 20);
    border-color: darken($primary-color, 20);
  }
}

.quickPayBtn {
  background-color: #007fb5;
  color: #fff;
  margin: 0 1rem;
  padding: 0 2rem;
  line-height: initial;
  height: 36px;
  display: inline-block;
  line-height: 36px;
  border-radius: 29px;
  transition: all 0.2s linear;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #02638d;
  }
}

.quickPayBtnGreen {
  background-color: #00ae69;
  color: #fff;
  margin: 0 1rem;
  padding: 0 2rem;
  line-height: initial;
  height: 36px;
  display: inline-block;
  line-height: 36px;
  border-radius: 29px;
  transition: all 0.2s linear;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #007f1e;
  }
}
.content {
  max-width: calc(1024px + 2rem);
  margin: auto;
}
.PageNotFound{
  text-align: center;
  background-color: #242729;
  padding: 15rem 0;
  font-size: 3rem;
  color: #fff;
}
.roundStudentsCard{
  text-align: center;
  padding: 1rem 0;
  .image{
    width: 150px;
    height: 150px;
    border-radius: 75px;
    margin: .5rem auto;
    background-size: cover;
  }
  .amount{
    color: $secondary-color;
    font-size: 1.3rem;
    font-weight: 600;
  }.name{
    color: $primary-color;
    font-size: 1.3rem;
    font-weight: 600;
  }
  a{
    background-color: $primary-color;
    color: #fff;
    padding: 0.2rem 1.5rem;
    margin-top: 0.5rem;
    display: inline-block;
    border-radius: 20px;
    transition: all 0.2s;
    &:hover{
      background-color: darken($color: $primary-color, $amount: 10);
      text-decoration: none;
    }
  }
}

.StudentSliderImage{

  display: flex;
  align-items: center;
  height: 100%;
  background-color: #5ab1e8;
  width: 100%;
  padding: 50%;
  background-position: center center;
  background-size: cover;
}
.carousel-root{
  overflow: hidden;
  width: 100vh;
}
.section-header{
  font-size: 3rem;
  font-weight: 400;
}

.socialData h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
}

.socialData {
  margin-left: 1rem;
  &.center-items{
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}

.socialData a {
  width: 60px;
  height: 60px;
  border: solid;
  display: inline-block;
  text-align: center;
  line-height: 52px;
  border-radius: 38px;
  margin-right: 20px;
  font-size: 1.5rem;
  @media (max-width: 900px) {
    width: 42px;
    height: 42px;
    font-size: 1.2rem;
    line-height: 40px;
  }
  &.tw {
    color: #4099ff;
  }
  &.fb {
    color: #3b5998;
  }
  &.mail {
    color: #5cddac;
  }&.linkedin {
    color: #0277b5;
  }
}
.anonymize{
  input{
      transform: scale(1.5);
      margin: 4px 6px;

    }
}