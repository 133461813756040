.Sponsorship {
  .wrapper {
    max-width: 1200px;
    margin: auto;
    @media (max-width: 1200px) {
      padding: 0 1rem;
    }
  }
  .Heading {
    color: #1b435d;
    font-weight: 300;
    padding: 2rem 0 1rem 0;
    font-size: 2rem;
    strong {
      font-weight: 400;
    }
    span {
      color: #00ae69;
    }
  }
}
