.adminContainer {
  min-height: 100vh;
  background-color: #efefef;
  padding: calc(56px + 1rem) 2rem 1rem calc(250px + 1rem);
  .Status {
    padding: 1rem;
    span {
      border-radius: 1rem;
      border: solid 1px;
      width: 100px;
      display: block;
      text-align: center;
      &.active {
        color: green;
        background-color: #00800010;
      }
      &.canceled {
        color: red;
        background-color: #ff000010;
      }
      &.pending {
        color: orange;
        background-color: #ffa60010;
      }
    }
  }

  .SponsorshipCard {
    display: flex;
    background-color: #fff;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    align-items: center;
    &.due {
      background-color: #ff3d0033;
    }
    .Images {
      display: flex;
      .Image {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        background-color: #f3f1f1;
        box-shadow: 0 0 0px 3px #ffffff inset;
        margin-right: -0.5rem;
      }
    }
    .Data {
      margin-left: 1rem;
      display: flex;
      .Names {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        min-width: 200px;
        justify-content: center;
        span {
          font-weight: 600;
          margin-left: 0.5rem;
          min-width: 75px;
          display: inline-block;
          position: relative;

          &::after {
            content: ":";
            margin: 0 0.5rem;
            position: absolute;
            right: 0;
          }
        }
      }

      .Dates {
        @extend .Names;
      }
      .Plan {
        @extend .Names;
      }
      .Tip {
        font-weight: 600;
        background-color: #e9e9e9;
        border-radius: 3px;
        padding: 0.5rem;
        width: 80px;
        text-align: center;
        font-size: 1.3rem;
        margin-right: 1rem;
        span {
          display: block;
          font-size: 0.8rem;
        }
      }
      .Amount {
        @extend .Tip;
        width: 100px;
      }
    }
    .Buttons {
      margin-left: auto;
    }
  }
  .HeaderText,
  .HeaderTextCMS {
    background-color: #fff;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    // font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .HeaderTextCMS {
    position: sticky;
    top: 54px;
    z-index: 10;
    box-shadow: 0px 3px 5px -5px #000;
    background-color: #d9d9d9;
    font-weight: 600;
    min-height: 54px;
  }
  .SponsorshipHeader {
    .HeaderText {
      strong {
        margin: 0 0.5rem;
      }
      .Status {
        font-size: 1rem;
      }
      .cancelBtn {
        margin-left: auto;
      }
    }
  }
  .Filters {
    margin-left: auto;
    color: rgb(111, 111, 111);
    font-size: 1rem;
    display: flex;
    select {
      margin-right: 1rem;
    }
  }
  .ViewPlan {
    background-color: #fff;
    padding: 1rem;
    display: flex;
    margin-bottom: 1rem;
    .Images {
      display: flex;
      .Image {
        width: 250px;
        height: 300px;
        background-position: center;
        background-color: #ccc;
        background-size: cover;
        margin-right: 1rem;
        border-radius: 3px;
        display: flex;
        align-items: flex-end;
        .Data {
          width: 100%;
          background-color: #003a5de6;
          color: #fff;
          position: relative;
          margin-top: 50px;
          padding: 0.5rem;
          font-size: 0.8rem;
          span {
            font-weight: 600;
            // display: block;
            font-size: 0.9rem;
          }
        }
      }
    }

    .DetailsBox {
      width: 100%;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;

        li {
          line-height: 32px;
          background-color: #f9f9f9;
          margin-bottom: 0.38rem;
          padding: 0 1rem;
          border-radius: 3px;
          display: flex;
          color: #333;
          align-items: center;
          strong {
            width: 200px;
            color: #000;
          }
          &.due {
            background-color: #f3bebe;

            span {
              padding: 0 1rem;
              background-color: #b10707ad;
              margin-left: 1rem;
              color: #000000;
              height: 24px;
              border-radius: 20px;
              line-height: 24px;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  .OfflinePay {
    background-color: #0583b5;
    padding: 1rem;
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    .formIn {
      display: flex;
      align-items: center;
      width: 100%;
      form {
        width: 100%;
      }
    }
    h4 {
      margin: 0;
      font-size: 1.2rem;
    }
    .errorMessage {
      position: absolute;
      top: 100%;
      font-size: 0.8rem;
      color: #b90606;
    }
    form {
      // display: flex;
      // align-items: center;
      // white-space: nowrap;
      // .input-group {
      //   max-width: 200px;
      //   min-width: 200px;
      // }
    }
    .note {
      margin-top: 1rem;
      background-color: #ffffff47;
      padding: 0.2rem 1rem;
      width: 100%;
      text-align: center;
      border-radius: 3px;
    }
  }
  .History {
    background-color: #fff;
    padding: 1rem;
  }
  .Table {
    width: 100%;
    overflow: auto;
    table {
      width: 100%;
      thead {
        background-color: #a9a9a9;
      }
      th,
      td {
        padding: 0.5rem 1rem;
      }
      tr {
        border-bottom: solid 1px #ccc;
      }
    }
  }
  .UsersList {
    display: flex;
    flex-wrap: wrap;
  }
  .UserTag {
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    box-shadow: 0 0 3px #ccc;
    background-color: #fff;
    color: #595959;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tagInfo {
      display: flex;
      align-items: center;

      .name {
        margin: 0 0.8rem;
        font-weight: 600;
      }
      .role {
        color: #007fb5;
        font-size: 0.8rem;
      }
      .Image {
        width: 60px;
        height: 60px;
        background-size: cover;
        background-position: center;
        border-radius: 3px;
      }
    }
    .tagButton {
      button {
        margin-left: 0.5rem;
      }
    }
    &:hover {
      background-color: #ccc;
    }
  }
  .Profile {
    @extend .ViewPlan;
    .Image {
    }
    .SocialLinks {
      i {
        font-size: 1.5rem;
        margin-right: 1rem;
      }
    }
  }
  .WhiteContent {
    background-color: #fff;
    padding: 1rem;
  }
  .loadingBtn {
    display: flex;
    align-items: center;

    i {
      margin-left: 1rem;
    }
    // &:disabled{

    // }
  }
  .ButtonFlex {
    display: flex;
    align-items: center;
    .Success {
      color: #02a76d;
      margin-left: 1rem;
    }
    .Err {
      color: #b33305;
      margin-left: 1rem;
    }
  }
}

.select {
  max-width: 285px !important;
  min-width: 285px !important;
  .css-2b097c-container {
    min-width: 200px;

    color: #000;
    .css-yk16xz-control {
      border-radius: 0 4px 4px 0;
    }
  }
  .input-group-prepend {
  }
}
.calenderWrapper {
  position: relative;
  z-index: 1000;
  .react-calendar {
    position: absolute;
    min-width: 300px;
    left: 1rem;
  }
}
.d-flex {
  display: flex;
}
.messageContainer {
  position: relative;
  .sponsorshipChat {
    display: flex;
    font-size: 0.8rem;
    background-color: #fff;
    margin-bottom: 4px;
    padding: 6px;
    max-width: 300px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s linear;
    &.active {
      background-color: #dbf2fe;
    }
    &:hover {
      background-color: #dbf2fe;
      box-shadow: 1px 1px 5px -2px #adadad;
    }
    .img {
      width: 50px;
      height: 50px;
      background-size: cover;
      background-image: url("./assets/user.png");
      margin-right: 2px;
    }

    .detailBox {
      padding: 0 0.5rem;
      flex: 1;
    }
    span {
      font-weight: 600;
    }

    .date {
      color: #919191;
      text-align: right;
      font-size: 0.7rem;
    }

    .new {
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: #2db90a;
      right: 5px;
      top: 5px;
      border-radius: 12px;
      box-shadow: 0 0 5px #4caf50;
    }
  }
  .messageBox {
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 305px);
    background-color: #fff;
    min-height: calc(100vh - 85px);
    padding: 0.5rem;
  }
  .messageBox {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        margin-bottom: 1.5rem;
        .bubble {
          background-color: #ebebeb;
          padding: 0.5rem;
          border-radius: 3px;
          position: relative;
          min-width: 300px;
          &.right {
            background-color: #dbf2ff;
            margin-left: auto;
            .date {
              left: 0;
            }
          }
          .date {
            position: absolute;
            top: 100%;
            font-size: 0.7rem;
            right: 0;
          }
        }
      }
    }
  }
  .inputBox {
    width: 100%;
    background-color: #03a9f4;
    padding: 1rem;
    input[type="text"] {
      flex: 1;
    }
  }
}
.paginations {
  margin: 2rem auto 4rem auto;
  display: flex;
  list-style: none;
  justify-content: center;
  li {
    a {
      padding: 5px 10px;
      background-color: #efefef;
      margin-left: 1px;
      display: block;
    }
  }
  .selected {
    a {
      background-color: #268ccc;
      border-color: #268ccc;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  .disabled {
    a {
      color: #868686;
      &:hover {
        color: #868686;
        cursor: not-allowed;
      }
    }
  }
}
.autoGrid {
  display: grid;
  grid-template-columns: 1fr 36px;
  grid-gap: 0.5rem;
}

.form-group {
  label.required {
    &::after {
      content: "*";
      color: #da4a4a;
    }
  }
}
.MediaContent {
  background-color: #bbb;
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  padding-top: 50%;
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: solid 3px #fff;
  border-radius: 3px;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .deleteButton {
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    right: 0.5rem;
  }
}
.fileUpload {
  background-color: #5ab1e8;
  padding: 0.3rem;
  border-radius: 4px;
  color: #fff;
}
.progress {
  width: 100%;
  height: 0.5rem;
}
.UpDownButtons {
  width: 56px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -56px;
  top: 0;
  button {
    margin: 0.5rem;
  }
}
.sectionContent {
  position: relative;
  max-width: calc(100% - 70px);
}
.RowColors {
  margin: 1rem 0;
  > div {
    padding: 1rem;
    background-color: #d3d3d3;
    border: solid 3px #fff;
    &:nth-child(2n + 1) {
      background-color: #ededed;
    }
    .RowColors {
      margin: 1rem 0;
      > div {
        padding: 1rem;
        background-color: #e7e7e7;
        border: solid 3px #fff;
        &:nth-child(2n + 1) {
          background-color: #f9f9f9;
        }
      }
    }
  }
}
.tableWrapper {
  height: calc(100vh - 400px);
  overflow: auto;
}
table {
  thead {
    position: sticky;
    top: 0;
  }
  td,
  th {
    line-height: 2.2rem;
    padding: 0.3rem 1rem !important;
  }
  .opacity-3 {
    opacity: 0.3;
  }
}
.rmdp-container {
  height: 38px !important;
}
.rmdp-input {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 1rem !important;
}
.sm-form {
  white-space: nowrap;
  input {
    font-size: 0.8rem;
    padding: 6px;
    height: 30px;
    width: 60px;
    border-radius: 3px;
    border: solid 1px #5ab1e8;
  }
  .rmdp-container{
    height: 30px !important;
    border: solid 1px #5ab1e8 !important;
    margin-top: 16px;
    input{
      width: 100%;
    }
  }
}
.codeBlock {
  padding: 0.5rem;
  background-color: #1e1e1e;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  strong{
    color: white;
  }
  pre {
    outline: 1px solid #323232;
    padding: 5px;
    margin: 5px;
  }
  .string {
    color: rgb(98, 98, 98);
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: rgb(3, 151, 82);
  }
}
