.View {
  max-width: 1200px;
  padding: 0;
  margin: auto;
  color: #706f6f;

  // progress bar current circle text
  .ProgressStudents {
    .Progress {
      .line {
        .on {
          background-color: #bbe4ff;
        }
        .inactive::before {
          background-color: #bbe4ff;
        }
        span {
          // .curentStartText::after{
          //   content: 'Sponsorship Period';
          //   font-size: larger;
          //   font-weight: bold;
          //   display: block;
          //   top: -5rem;
          //   left: 10rem;
          //   color: #ff895d;
          //   position: absolute;
          // }

          &.current:before {
            background-color: #ff895d;
          }
          &.current {
            small::before {
              content: "";
              position: absolute;
              top: 0;
              left: 12px;
              transform: translateY(-11px);
              width: 2px;
              height: 10px;
              background-color: #ff895d;
              display: block;
            }
            small.curentStartText {
              color: #ff895d;
            }
          }
          &.current.pointed::before {
            top: 0;
          }
          &.pointed.current::after {
            display: none;
          }
          &.pointed.current small::before {
            top: 0.5rem;
            left: 1.3rem;
          }
          &.pointed.current small.curentStartText {
            color: #ff895d;
          }
          &.current::after {
            content: attr(data-gtext);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) translateX(-0.65rem);
            width: 1.7rem;
            text-align: center;
            font-size: 0.6rem;
            font-weight: 600;
            color: #ffffff;
          }
        }
        span[data-gtext="O/L"] big {
          transform: translateX(-3rem);
        }
        span[data-gtext="A/L"] big {
          transform: translateX(-2.5rem);
        }
      }
      .current-title {
        color: #333;
      }
      .uni-title {
        transform: translateX(-8rem);
      }
      .period-tittle {
        transform: translateX(-7rem) translateY(-1rem);
        color: #ff895d;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .gittCardBtnArea {
    display: flex;
    align-items: center;
    background-color: #efeff0;
    margin-bottom: 2rem;
    padding: 1rem;
    justify-content: center;
    h4 {
      font-size: 1.2rem;
      margin: 0;
      color: #333;
      margin-right: 1rem;
    }
    button {
      margin: 0;
      position: relative;
      span {
        top: calc(100% + 5px);
      }
    }
  }

  .imageThumb {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    text-align: center;
    color: #707070;

    img {
      margin: 1rem auto;
      max-width: 100%;
      width: 300px;
      border: solid 1px #ccc;
    }
  }
  .PaymentTab {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #6c6c6c;
    font-size: 1.5rem;
    background-color: #e3e3e3;
    width: 100%;
    margin: 0 0 2rem 0;

    text-align: center;
    @media (max-width: 767px) {
      font-size: 1rem;
    }
    li {
      padding: 1rem;
      width: 25%;
      box-shadow: 0 0 0px 1px #ccc;
      cursor: pointer;
      justify-content: center;
      font-size: 1rem;
      @media (max-width: 767px) {
        font-size: 0.8rem;
      }
      &.active {
        background-color: #00af6b;
        color: #fff;
      }
    }
  }

  button.Check {
    background-color: transparent;
    border: navajowhite;
    margin: auto;
    display: block;
    color: #252525;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
    }
    &.active {
      i {
        &::before {
          opacity: 1;
          color: #00af6b;
        }
      }
    }
    i {
      border: solid 1px #ccc;
      border-radius: 16px;
      width: 20px;
      height: 20px;
      font-size: 1.5rem !important;
      margin-right: 10px;
      position: relative;
      &::before {
        opacity: 0;
        position: relative;
        top: -4px;
        left: -1px;
      }
    }
  }
  .priceTitle {
    text-align: center;
    margin: 2rem auto;
    h3 {
      font-weight: 500;
      span:first-child {
        color: #00af6b;
      }
    }
    small {
      color: #248acf;
    }
  }
  .custom-switch {
    // Target only the toggle switch (input) for cursor pointer
    input[type="checkbox"] {
      cursor: pointer;
      margin-top: 0.1rem;
    }

    .custom-control-label::before {
      cursor: pointer;
      margin-top: 0.1rem;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;               // Changes the text or icon color to white when checked
      border-color: #00af6b;    // Changes the border color to blue when checked
      background-color: #00af6b; // Changes the background color to blue when checked
    }

    .custom-control-label::after {
      cursor: pointer;
      background-color: #000000;
      margin-top: 0.1rem;
    }

    // Optionally, you can target the label or other elements if needed
    label {
      cursor: pointer; // Ensure the label doesn't have the pointer
      color: #706f6f;
    }
  }
  .optianal {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    font-size: 15px;
    padding: 1rem;
    margin: 2rem 0;
    color: #333;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    span.optianalSpan {
      display: flex;
      align-items: center;
      font-weight: 600;
      width: 120px;
      .css-2b097c-container {
        width: 136px;
        margin: 0 10px;
        font-size: 15px;
      }
      .tip-input-wraper {
        width: 75%;
        position: relative;
        margin: 0 auto;
        .tip-input {
          width: 100%;
          font-size: 15px;
          padding: 5px 5px;
          border-radius: 4px;
          border: 1px solid #70707050;
        }
        .tip-input:focus {
          border-color: #248acf;
          outline-color: #248acf;
        }
      }

      .tip-input-wraper::after {
        content: "%";
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: #9d9d9d;
      }
    }
  }
  .TotalPayment {
    color: #1b435d;
    text-align: center;
    margin-bottom: 2rem;
    span {
      color: #00af6b;
    }
  }
}
$rccs-card-ratio: "0.5";
