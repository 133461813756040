.forgotSign {
    display: flex;
    width: 100%;
    // justify-content: center;

    > div {
        display: flex;
        align-items: center;
    }
    button{
      font-size: 1rem;
      padding: 0 !important;
      margin-left: 5px;
    }
}


.LoadinContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.custom-switch {
    // Target only the toggle switch (input) for cursor pointer
    input[type="checkbox"] {
      cursor: pointer;
    }

    .custom-control-label::before {
      cursor: pointer;
    }

    .custom-control-label::after {
      cursor: pointer;
    }

    // Optionally, you can target the label or other elements if needed
    label {
      cursor: pointer; // Ensure the label doesn't have the pointer

    }
  }
