.CreateCampaignForm {
  background-color: #f9fdff;
  padding: 2rem;
  margin-bottom: 4rem;
  border: solid 1px #ccc;
  border-radius: 1rem;
  color: #6b6b6b;
  .inputContainer {
    margin-bottom: 1.5rem;
    &.textarea {
      .srv-validation-message {
        bottom: auto;
        top: 10px;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        width: 100%;
      }
    }
  }
  .checkGoal {
    color: #333;
    width: 100%;
    display: flex;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    span {
      margin-top: 1px;
    }
    &.true {
      i {
        border: solid 1px #ffffff00;
        &::before {
          opacity: 1;
        }
      }
    }
    i {
      left: 0;
      top: 5px;
      bottom: 0 !important;
      border: solid 1px #808080;
      width: 19px;
      height: 19px;
      border-radius: 30px;
      &::before {
        opacity: 0;
        color: #00ae69;
      }
    }
  }
  .inputSideCont {
    background-color: #e5f4fb;
    padding: 5px;
    display: flex;
    border-radius: 2rem;
    color: #5ab1e8;
    background-color: #efefef;
    border: solid 1px;
    margin-bottom: 1.5rem;
    position: relative;
    .srv-validation-message {
      right: 0.5rem;
      top: -22px;
    }

    .inputContainer {
      position: relative;
      margin-bottom: 0rem;
     
      input[type="text"] {
        padding-left: 30px;
        padding-right: 60px;
      }
      &::after {
        content: "USD";
        position: absolute;
        right: 10px;
        top: 7px;
      }
      &::before {
        content: "$";
        position: absolute;
        top: 6px;
        left: 16px;
      }
    }
    .CamTag {
      font-size: 0.8rem;
      padding: 0px 9px;
      display: flex;
      align-items: center;
      i {
        font-size: 1.3rem;
        margin-right: 10px;
      }
    }
  }
  .campainPhoto {
    .fileSelect {
      display: flex;
      align-items: flex-start;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      span {
        color: #3a89bb;
        cursor: pointer;
        &:hover {
          color: #24668f;
        }
      }
      .file {
        position: relative;
        overflow: hidden;
        padding-right: 10px;
        // margin-right: 10px;
        cursor: pointer;
        color: #ccc;
        background-color: #fff;
        width: 100%;
        border: solid 1px #5ab1e8;
        padding: 5px 1rem;
        border-radius: 2rem;
        input {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        small {
          display: block;
          color: #636363;
          text-transform: none;
          // background-color: #dedede6e;
          padding: 5px 0.8rem;
          border-radius: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: all 0.2s linear;
        }
        &:hover {
          small {
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }
  }
  .imageUploaded {
    width: 100%;
    height: 220px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #eee;
    margin-bottom: 1rem;
  }
}
