.Birthday {
  .createCampaignPop{
      >div{
          position: fixed;
      width: 300px;
      height: 300px;
      top: calc(50% - 150px);
      left: calc(50% - 150px);
      z-index: 1000;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-radius: 3px;
      h3{
          text-transform: uppercase;
          text-align: center;
          color: #ffffff;
          font-weight: 900;
          background-color: #002535;
          padding: 1.5rem;
          line-height:3rem ;
      
      }
      a,button{
          text-decoration: none;
          color: #fff;
          background-color: #007fb5;
          display: block;
          border: none;
          padding: .5rem 1rem;
          border-radius: 2rem;
          transition: all 0.2s linear;
          margin: 2rem 0.5rem 1rem 0.5rem;
          width: 100px;
          text-align: center;
          text-transform: uppercase;
          &:hover{
              background-color: #025f86;
              color: #fff9;
          }
      }
      button{
          background-color: #ff895d;
          &:hover{
              background-color: #c4603c;
              color: #fff9;
          }
      }
      }
      &::after{
          content: "";
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: #000000b5;
          z-index: 200;
          top: 0;
          left: 0;
      }
  }
}
