.Profile {
  .Image {
    overflow: hidden;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    img {
      width: 100%;
    }
  }
  .SponsorDetail {
    color: #4e4e4e;
    h1 {
      color: #5ab1e8;
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 300;
    }

    .tag {
      display: flex;
    }

    .tag span {
      padding: 5px 1rem;
      border: solid 1px #ff895d;
      border-radius: 7px;
      color: #ff895d;
      margin-right: 1rem;
      margin: 1rem 0.5rem 1rem 0;
      font-weight: 300;
    }

    .tag span:nth-child(2) {
      color: #5ab1e8;
      border-color: #5ab1e8;
    }

    .connection a {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background-color: #1384c8;
      margin: 0 0 0 0.6rem;
      text-align: center;
      color: #fff;
      line-height: 30px;
    }

    .connection {
      display: flex;
      align-items: center;
    }

    .connection a.tw {
      background-color: #77cbef;
    }
  }
  .generalDetail {
    color: #727272;
    h1 {
      color: #5ab1e8;
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }
  .SponserMap {
    color: #1b435d;
    display: flex;
    flex-direction: column;
    border-left: solid 1px #efefef;
    padding-left: 2rem;
    h1 {
      color: #5ab1e8;
      font-size: 1.4rem;
      text-transform: uppercase;
    }
    svg {
      width: 130px;
      fill: #efefef;
      margin-left: auto;
      stroke: #c6c6c6;
      stroke-width: 0.1px;
    }
  }
}
.LoadInsideText {
  display: inline-block;
  position: relative;
  top: 10px;
}
.stList {
  display: inline-block;
  a {
    &::after {
      content: ", ";
    }
    &:last-child {
      &::after {
        content: "";
      }
    }
  }
}
