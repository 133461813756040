.Navigation {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  position: relative;
  top: 20px;
  a,
  span,
  button {
    color: #fff;
    text-decoration: none;
    margin: 0.5rem 1.5rem;
    font-weight: 600;
    display: block;
    padding: 3px 0;
    &:hover {
      box-shadow: 0px 3px 0 0px #fff;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
    }
  }
  > li {
    position: relative;
    .center {
      text-align: center;
    }
    .LoadingButton .lds-ellipsis {
      height: 20px;
      div {
        top: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px 1px #f5875c inset;
      }
    }
    &.bordered {
      border-radius: 3rem;
      border: solid 1px #fff;
      @media (max-width: 900px) {
        border: none;
        padding-left: 0;
      }
      button {
        margin: 0.5rem 2rem;
        background-color: transparent;
        border: none;
        @media (max-width: 900px) {
          width: 100%;
          margin: 0;
          border-radius: 1rem;
          padding: 10px;
          border: solid 1px #fff;
        }
        &:focus {
          outline: none;
        }
      }
    }
    &:hover {
      ul {
        top: 47px;
        transform: translateY(0);
        @media (max-width: 900px) {
          top: 0;
        }
        a {
          white-space: nowrap;
        }
      }
    }
  }
  ul {
    &.blue {
      background-color: #5ab1e8;
    }
    transition: transform 0.1s linear;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #0008;
    border-radius: 10px;
    z-index: 100;
    left: 0;
    top: -1000px;
    transform: translateY(50px);
  }
}
