.PaymentMessage {
  .modal-dialog {
    margin: 5rem auto 2rem auto;
  }
  .modal-header {
    position: relative;
  }
  .TitleContent {
    text-align: center;
    margin: 3rem 0 2rem 0;
    width: 100%;
    padding-left: 2rem;
    h4 {
      color: #ccc;
    }
    small {
      color: #5ab1e8;
    }
    img {
      position: absolute;
      top: -50px;
      border-radius: 100%;
      box-shadow: 0 0 3px 1px #5ab1e8;
      background-color: #fff;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
    }
  }
  button {
    font-size: 2rem;
  }
  .success {
    color: #283c00;
    background-color: #08c53347;
    padding: 1rem;
    text-align: center;
    border-radius: 3px;
    border: solid 1px #90c12a;
  }
  .error {
    color: #3c0000;
    background-color: #c53c0847;
    padding: 1rem;
    text-align: center;
    border-radius: 3px;
    border: solid 1px #8a2701;
  }
}
