.MultiSelect {
  width: 100%;
  box-shadow: 0 0 0 1px #8fcef5;
  height: 38px;
  line-height: 38px;
  border-radius: 30px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  i{
    position: absolute;
    top: 10px;
    right: 11px;
    z-index: 1;
    color: #80c2eb;
  }
  .LengthItem {
    background-color: #8fcef5;
    // color: #fff;
  }
  .Items {
    width: 100%;
    list-style: none;
    padding: 0;

    li {
      padding: 0 1rem;
      font-size: 0.8rem;
    }
  }
  &.open {
    overflow: visible;
    .NoValues {
      display: none;
    }
    .Items {
      max-height: 300px;
      overflow: auto;
      width: calc(100% + 1rem);
      list-style: none;
      padding: 0;
      position: relative;
      z-index: 100;
      left: -0.5rem;
      top: 38px;
      li {
        background-color: #fff;
        position: relative;
        z-index: 11;
        border-bottom: 1px solid #fff;
        transition: 0.2s ease-in;
        &:hover {
          cursor: pointer;
          background-color: #efefef;
        }
        &:first-child {
          border-radius: 3px 3px 0 0;
        }
        &:last-child {
          border-radius: 0 0 3px 3px;
        }
        &.Selected {
          background-color: #5ab1e8;
          color: #fff;
          &::after {
            content: "\2713";
            position: absolute;
            right: 0;
            width: 30px;
            height: 38px;
            font-size: 1rem;
            line-height: 35px;
            text-align: center;
            top: 0;
            font-family: monospace;
          }
        }
      }
    }
    .backDrop {
      position: fixed;

      background-color: #0000004a;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .LengthItem {
      display: none;
    }
  }
}
