.ChatBuble {
  display: flex;
  margin: 1rem 0;
  align-items: flex-start;
  flex-direction: row-reverse;

  &.StudentBuble {
    flex-direction: row;
    img {
      margin-right: 1rem;
      margin-left: 0;
    }
    .msg {
      background-color: #efefef;
      color: #808080;
      &::after {
        border: solid 15px transparent;
        content: "";
        border-left-color: transparent;
        border-right-color: #efefef;
        width: 0;
        height: 0;
        position: absolute;
        left: -25px;
        top: 1rem;
      }
      a {
        display: flex;
        width: auto;
        flex-direction: column;
        background-color: #eaeaea;
        padding: 8px;
        border-radius: 3px;
        margin-top: 10px;
        text-align: center;
        margin-left: auto;
        align-items: center;
        font-size: 12px;
        align-items: center;
        overflow: hidden;
        img {
          width: auto;
          height: 100px;
          margin: 0;
        }
      }
    }
  }
  img {
    width: 80px;
    height: 80px;
    border: solid 1px #efefef;
    margin-right: 0;
    margin-left: 1rem;
    border-radius: 3px;
  }
  .msg {
    background-color: #5ab1e8;
    padding: 1rem;
    border-radius: 3px;
    color: #fff;
    position: relative;
    max-width: calc(100% - 192px);
    a {
      display: flex;
      width: 100%;
      flex-direction: column;
      // color: #fff;

      background-color: #eaeaea;
      padding: 8px;
      border-radius: 3px;
      margin-top: 10px;
      text-align: center;
      align-items: center;
      font-size: 12px;
      overflow: hidden;
      img {
        width: auto;
        height: 100px;
        margin: 0;
      }
    }
    @media (max-width: 767px) {
      max-width: calc(100% - 96px);
    }
    &::after {
      border: solid 15px transparent;
      content: "";
      border-left-color: #5ab1e8;
      width: 0;
      height: 0;
      position: absolute;

      left: auto;
      right: -25px;
      top: 1rem;
    }
  }
}
