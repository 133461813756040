.Sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  z-index: 10;
  background-color: #292929;
  left: 0;
  top: 0;

  color: #fff;
  .logo {
    padding: 0.3rem 1rem;
    display: block;

    img {
      height: 50px;
    }
  }
  ul {
    margin: 0;
    margin-top: 2rem;
    padding: 0;
    list-style: none;
    a {
      background-color: #373737;
      display: block;
      margin-bottom: 1px;
      padding: 0.8rem 1rem;
      color: #ffffff80;
      transition: all 0.2s linear;
      letter-spacing: 1px;
      &:hover {
        text-decoration: none;
        background-color: #1b1b1b;
      }
      &.active {
        background-color: #1b1b1b;
        box-shadow: 3px 0 0 0 #fff inset;
        color: #fff;
      }
    }
  }
}
