.RightSideBarToggler {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 300px;
  z-index: 10;
  left: 100%;
  overflow: hidden;
  &.open {
            left: 0;
  }
  .WhiteContent {
    z-index: 1000;
    padding: 4rem 2rem;
    flex-direction: column;
    overflow: auto;
    background-color: #fff;
    width: 100%;
    grid-column: 2 / 3;
  }
  .BackDrop {
    z-index: 999;
    background-color: #00000041;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
}
