.FundSecondSection {
  .wrapper {
    max-width: 900px;
    margin: auto;
  }
  .largeText {
    margin: 5rem 0;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.6rem;
  }
  .DirectStudent {
    min-height: 500px;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    .ShortDiscription {
      padding: 3rem;
      color: #fff;
      max-width: 700px;
      font-size: 1.3rem;
      font-weight: 300;
    }
  }
  .WHoItWOrk {
    padding: 5rem 1rem;
    background-size: cover;
    .HowItBox {
      padding: 0 2rem;
      text-align: center;
      border-right: solid 1px #ccc;
      &.last {
        border: none;
      }
      h3 {
        margin: 1rem 0;
      }
      p {
        font-size: 0.8rem;
        color: #687f91;
      }
    }
  }
  .meetAll {
    margin-bottom: 3rem;
    a {
      margin-bottom: 0;
    }
    small {
      color: #1b435d;
    }
  }
}
