.MeetMore {
  max-width: 1200px;
  margin: auto;
  padding: 0 1rem;
  h2{
    font-size: 38px;
  }
}
.centerItem {
  margin: 2rem auto;
}