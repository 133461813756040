footer {
  background-color: #242729;

  .wrapper {
    max-width: calc(100% - 200px);
    margin: 0 auto;
    padding-top: 60px;

    @media (max-width: 900px) {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    &.line {
      border-top: solid 1px #fff5;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #fff;

      .link {
        display: flex;
        white-space: nowrap;

        span {
          color: rgba(255, 255, 255, 0.5215686275);
        }

        a {
          color: inherit;
        }
      }
      .customized {
        font-size: 0.8rem;
        display: flex; /* Ensures items are aligned side by side on larger screens */

        @media (max-width: 900px) {
          flex-direction: column; /* Changes the layout to column on smaller screens */

          .link {
            margin-bottom: 15px;
            flex-direction: column; /* Make the links stack vertically */
            text-align: center; /* Align text to the center */
          }

          .weLove {
            color: rgba(255, 255, 255, 0.5215686275);
            margin-bottom: 10px;
          }

          .text-right {
            text-align: center !important;
            margin-top: 10px;
          }
        }
      }

      a {
        padding: 0 15px;

        &.bl {
          border-left: solid 1px #fff;
        }

        @media (max-width: 900px) {
          &.bl {
            border-left: none; /* Removes the border-left on mobile screens */
          }
        }

        &.active {
          color: #fff;
        }

        @media (max-width: 768px) {
          display: block;
          width: 100%;
          border-left: none;
          padding: 0;
          text-decoration: none;
          text-align: center;
        }

        &:first-child {
          border-left: none;
          padding-left: 0;
        }
      }
    }
  }
}
