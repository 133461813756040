$blue: #5ab1e8;

.PageSections {
  .blueBoldText{
    font-weight: 600;
    font-size: 1rem;
    color: $blue;
  }
  .DirectStudent {
    min-height: 500px;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    background-color: $blue;
    .ShortDiscription {
      padding: 3rem;
      color: #fff;
      max-width: 700px;
      font-size: 1.3rem;
      font-weight: 300;
    }
  }
  .WHoItWOrk {
    padding: 5rem 1rem;
    background-size: cover;
    .HowItBox {
      padding: 0 2rem;
      text-align: center;
      border-right: solid 1px #ccc;
      &.last {
        border: none;
      }
      h3 {
        margin: 1rem 0;
      }
      p {
        font-size: 0.8rem;
        color: #687f91;
      }
    }
  }
  .BlueImpactArea {
    padding: 60px;
    background-color: #1b435d;
    color: #fff;
    .handrend {
      font-size: 6rem;
      font-weight: 600;
      color: #ff895d;
      text-align: center;
      line-height: 140px;
      font-family: "Fjalla One", sans-serif;
    }
    h2 {
      color: #d5eeff;
      font-size: 24px;
    }
  }

  .ThirdSection {
    background-size: cover;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    .wrapper {
      max-width: 900px;
      margin: 0 auto;
      padding: 8rem 1rem;
      h1 {
        color: #d5eeff;
        font-weight: 300;
        font-size: 46px;
        strong {
          font-weight: 600;
          color: #ff895d;
        }
      }

      .donate-line {
        width: 100%;
        max-width: 600px;
        margin: 60px auto;
        display: flex;
        flex-direction: column;
        a {
          margin-left: auto;
          background-color: #0009;
          padding: 5px 15px;
          color: #fff;
          font-size: 14px;
          border-radius: 31px;
          text-decoration: none;
          font-weight: 400;
          position: relative;
          left: 50px;
          @media (max-width: 768px) {
            left: 0;
          }
        }
        .line {
          height: 10px;
          background-color: #0005;
          margin: 10px 0;
          border-radius: 5px;
          padding: 0 3px;
          overflow: hidden;
        }

        .line span {
          height: 4px;
          background-color: #5197c3;
          display: block;
          margin-top: 3px;
          border-radius: 5px;
        }

        .target-tag {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          font-weight: 400;
          .target {
            color: #ff895d;
          }

          .raised {
            margin-right: 40%;
            color: #5197c3;
          }
        }
      }
    }
    .width-100 {
      width: 275px;
      margin: auto;
      small {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .inContent {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 1rem;
  }
  .featuredImage {
    min-height: 250px;
    width: 100%;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 7rem !important;
  }

  .ash-section {
    background-color: #ebebeb;
  }
  // section {
  //   padding: 2rem 0;
  // }
  .imageRoundWithTitle {
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    &.verticle {
      flex-direction: row;
      align-items: stretch;
      text-align: left;
      .img {
        margin-right: 1rem;
        min-width: 150px;
        min-height: 150px;
      }
    }
    .img {
      width: 150px;
      height: 150px;
      overflow: hidden;
      background-position: center center;
      background-size: cover;
      border-radius: 100px;
      margin-bottom: 1rem;
      img {
        width: 100%;
      }
    }
  }
  .cardImage {
    position: relative;
    background-size: cover;
    background-position: center;
    min-height: 150px;
  }
  .cardMainImage {
    position: relative;
    background-size: cover;
    background-position: center;
    min-height: 300px;
  }
  .gMap {
    filter: grayscale(1);
    iframe {
      border: none;
    }
  }
  iframe {
    max-width: 100%;
  }
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .innerAbsolute {
    position: relative;

    .youtubeThumbnailList {
      position: absolute;
      list-style: none;
      padding: 0;
      width: 100%;
      overflow: auto;
      height: 100%;
      @media (max-width: 767px) {
        position: relative;
        margin: 1rem 0;
      }
      li {
        display: flex;

        padding: 0.5rem;
        cursor: pointer;
        transition: all 0.3s linear;
        &.active,
        &:hover {
          background-color: #ebebeb;
        }
        img {
          width: 100px;
          padding-right: 1rem;
          object-fit: cover;
        }
      }
    }
  }
  .EighthSection {
    padding: 60px;
    h1 {
      color: #1b435d;
      font-weight: 300;
      text-align: center;
      font-size: 46px;
    }
    .col-md-4 {
      display: flex;
      margin-top: 30px;
      font-weight: 300;
      .Icon {
        width: 60px;
        font-size: 30px;
        color: #5ab1e8;
        text-align: center;
      }
      .iconContent{
        flex: 1;
        a {
          color: inherit;
          font-size: 18px;
          font-weight: 400;
          color: #1b435d;
          strong {
            font-weight: 600;
          }
        }
      }
    }
  }
  
  .SixthSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  .nav-tabs {
    margin-bottom: 10px;
    a {
      border: none;
      box-shadow: none;
      color: #333;
      font-size: 18px;
      line-height: 32px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      &:hover {
        color: #ff895d;
      }
      &.active {
        box-shadow: 0 2px 0 #ff895d;
        color: #000;
      }
    }
  }

  .tab-content {
    min-height: 150px;
    background-color: #1b435d;
    margin-bottom: 20px;
    width: 100%;
  }
}
.SeventhSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 30px 0;

  .nav-tabs {
    margin-bottom: 10px;
    a {
      border: none;
      box-shadow: none;
      color: #333;
      font-size: 18px;
      line-height: 32px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      img {
        filter: grayscale(1);
        height: 50px;
      }
      &:hover {
        color: #ff895d;
      }
      &.active {
        box-shadow: 0 2px 0 #ff895d;
        color: #000;
      }
    }
  }

  .tab-content {
    min-height: 150px;
    background-color: #1b435d;
    // margin-bottom: 20px;
    width: 100%;
    .wrapper {
      max-width: 1024px;
      margin: 30px auto 70px auto;
      color: #fff;
    }
    &.reverse {
      display: flex;

      flex-direction: column-reverse;
      margin-top: 40px;
      text-align: center;

      color: #fff;
      .nav-tabs {
        width: 100%;
        background-color: #eaeaea;
        justify-content: center;
        padding-bottom: 30px;
        margin-bottom: 0;
        a {
          opacity: 0.7;
          margin-top: 20px;
        }
        a.active {
          box-shadow: none;
          background-color: transparent;
          opacity: 1;
          position: relative;
          &::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 20px 0 20px;
            border-color: #1b435d transparent transparent transparent;
            position: absolute;
            top: -20px;
            left: 20px;
          }
        }
      }
    }
  }
}

}
