.ProgressStudents {
  width: 100%;
  padding-left: 1.5rem;

  .Progress {
    display: flex;
    position: relative;
    align-items: center;
    height: 80px;
    .hiligted {
      height: 3px;
      position: absolute;
      top: 16px;
      z-index: 10;
    }
    big {
      position: absolute;
      top: -20px;
      color: #b5b5b5;
      right: 100%;
      font-size: 10px;
      font-weight: 600;
      white-space: nowrap;
      font-family: "Lato", sans-serif !important;
      font-style: normal;
      @media (max-width: 767px) {
        color: transparent;
      }
    }
    .line {
      height: 3px;
      background-color: #ff895d;
      display: flex;
      // margin-top: 1rem;
      flex: 1;
      color: #fff;

      span {
        flex: 1;
        position: relative;
        display: block;
        font-size: 0.8rem;
        opacity: 0;
        big {
          bottom: calc(100% + 11px);
          top: auto;
          right: calc(100% + 22px);
        }
        &.current,
        &.start {
          opacity: 1;
          &::before {
            background-color: #5ab1e8;
            left: -11px;
            width: 20px;
            height: 20px;
            opacity: 1;
            position: absolute;
          }
          small {
            top: 1rem;
            color: #333;
            opacity: 1;
            white-space: nowrap;
          }
        }
        &.current {
          small {
            display: none;
            &.curentStartText {
              top: 1.6rem;
              display: block;
              color: #5ab1e8;
              // font-size: 0.7rem;
            }
          }
          // small::before{
          //   content: '';
          //   position: absolute;
          //   top: 0;
          //   left: 12px;
          //   transform: translateY(-11px);
          //   width: 2px;
          //   height: 10px;
          //   background-color: #5ab1e8;
          //   display: block;
          // }
          &.pointed {
            small {
              display: block;
            }
          }
        }
        &.current::before{
          width: 1.7rem;
          height: 1.7rem;
          top: 50%;
          transform: translateY(-50%);
        }
        // &.current::after{
        //   content: attr(data-gtext);
        //   position: absolute;
        //   top: 50%;
        //   left: 0;
        //   transform: translateY(-50%) translateX(-0.3rem);
        //   font-size: 0.7rem;
        //   font-weight: 600;
        //   color: #ffffff;
        // }
        small {
          position: absolute;
          width: 20px;
          height: 20px;
          top: -0.8rem;
          text-align: center;
          line-height: 18px;
          font-size: 0.55rem;
          left: -11px;
          color: #b5b5b5;
          opacity: 0;
          @media (max-width: 767px) {
            color: transparent;
          }
        }

        &.on {
          background-color: #5ab1e8;
          opacity: 1;

          &:before {
            background-color: #5ab1e8;
            left: -11px;
          }
        }
        &.off {
          background-color: #eaeaea;
          opacity: 1;

          &::before {
            background-color: #eaeaea;
            left: -11px;
          }
        }
        &.pointed {
          opacity: 1;

          small {
            font-size: 0.8rem;
            line-height: 36px;
            width: 46px;
            height: 46px;
            top: -1rem;
            font-weight: 600;
            left: -23px;
            color: #fff;
            opacity: 1;
            @media (max-width: 767px) {
              width: 40px;
              height: 40px;
              // top: -1.15rem;
            }
            &.curentStartText {
              top: 1.4rem;
              color: #333;
              font-size: 0.6rem;
              font-weight: 400;
            }
          }
          &::before {
            width: 46px;
            height: 46px;
            top: -1.35rem;
            left: -23px;
            @media (max-width: 767px) {
              width: 40px;
              height: 40px;
              top: -1.15rem;
            }
          }
        }
        &::before {
          content: "";
          // width: 22px;
          // height: 22px;
          background-color: #ff895d;
          display: block;
          border-radius: 30px;
          position: absolute;
          left: 0;
          top: -0.5rem;
        }
        .current-title{
          color: #333;
        }
      }
      // span[data-gtext="O/L"] big{
      //   transform: translateX(-0.3rem);
      // }
    }
    // .uni-title{
    //   transform: translateX(-4rem);
    // }
    i {
      border-radius: 30px;
      display: block;
      background-color: #ff895d;
      width: 46px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      font-size: 0.8rem;
      color: #fff;
      position: relative;
      small {
        position: absolute;
        top: -2rem;
        color: #333;
        left: 10px;
        font-family: "Lato";
        white-space: nowrap;
      }
      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
      &.active {
        background-color: #5ab1e8;
      }
    }
  }
  &.sm {
    max-width: 200px;
    margin-top: -1.5rem;
    min-width: 200px;
    .Progress {
      big {
        display: none;
      }
      .line {
        span {
          &.pointed {
            &::before {
              width: 12px;
              height: 12px;
              left: -5px;
              top: -5px;
            }
            small {
              opacity: 1;
            }
          }
          small {
            width: 15px;
            height: 15px;
            top: 0.2rem;
            line-height: 31px;
            left: -7px;
            font-size: 0.63rem;
            opacity: 0;
            color: #000;
          }
          large {
            display: none;
          }
          &.current,
          &.start {
            &.pointed {
              &::before {
                background-color: #5ab1e8;
                left: -7px;
                top: 1px;
                border-radius: 13px;
                width: 12px;
                height: 12px;
              }
            }
            &::before {
              bottom: 0;
              left: -1px;
              // background-color: transparent;
              top: auto;
              width: 2px;
              height: 8px;
              // border-width: 4px 4px 10px 4px;
              // border-style: solid;
              // border-color: #0000 #0000 #5ab1e8 #0000;
              border-radius: 0;
              // background-color: red;
            }
            small {
              // opacity: 1;
              // bottom: 15px;
              // color: #268ccc;
              // top: auto;
            }
          }
          &.current {
            .curentStartText {
              display: none;
            }
          }
        }
      }
      i {
        width: 12px;
        height: 12px;
        color: #000;
        small {
          display: none;
        }
      }
    }
  }
}
