.Button {
  text-align: center;
  border-radius: 35px;
  background-color: #ff895d;
  color: #ffffff;
  display: inline-block;
  padding: .9rem 2rem;
  color: #fff !important;
  text-decoration: none;
  min-width: 100px;
  border: none;
  font-size: 22px !important;
  transition: all .2s ;
  &:disabled {
    background-color: #ff895d55;
    cursor: not-allowed;
    &:hover {
      background-color: #ff895d55;
    }
  }
  &.full {
    width: 100%;
  }
  &.sm{
    padding: .4rem 1.5rem;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    background-color: #f5784b;
  }
  &.blue{
    background-color: #268ccc;
    &:hover {
      background-color: #1d76ad;
    }
  } 
  &.pink{
    background-color: #ff5d8f;
    &:hover {
      background-color: #db4c79;
    }
  }&.green{
    background-color: #00af6b;
    &:hover {
      background-color: #028e58;
    }
  }
}
.blueButton {
  text-align: center;
  border-radius: 35px;
  background-color: #5ab1e8;
  color: #ffffff;
  display: inline-block;
  padding: 0.9rem 2rem;
  color: #fff !important;
  text-decoration: none;
  min-width: 100px;
  border: none;
  font-size: 25px !important;
  &:disabled {
    background-color: #5ab1e855;
    cursor: not-allowed;
    &:hover {
      background-color: #5ab1e855;
    }
  }
  &.full {
    width: 100%;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    background-color: #3a89bb;
  }
}
.LinkLineButton {
  border: 2px;
  border-style: solid;
  border-radius: 30px;
  color: #ff895d;
  background-color: #fff;
  border-color: #ff895d;
  font-weight: 400;
  padding-right: 50px;
  padding-left: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 25px;
  margin: 2rem auto;
  display: table;
  padding: 10px 2rem;
  transition: all 0.2s linear;
  &.sm {
    font-size: 0.8rem;
    padding: 0.3rem 1rem;
    margin: 1rem 0;
  }
  &.Blue {
    border-color: #5ab1e8;
    color: #5ab1e8;
    &:hover {
      background-color: #3b95cc;
      text-decoration: none;
      color: #fff;
    }
  }
  &.Green {
    border-color: #00af6b;
    color: #00af6b;
    &:hover {
      background-color: #028e58;
      text-decoration: none;
      color: #fff;
    }
  }
  &:hover {
    background-color: #ff885d50;
    text-decoration: none;
    color: #ff895d;
  }
}
.payButton {
  background-color: transparent;
  border: none;
  padding: 0.8rem 2rem;
  color: #fff;
  border-radius: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: all 0.2s;
  span {
    position: absolute;
    top: calc(100% - 32px);
    color: #333;
    font-size: 12px;
    line-height: 12px;
    opacity: 0;
    transition: all 0.2s linear;
  }
  &:hover {
    span {
      top: calc(100% - 10px);
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
  }
  &.full {
    width: 100%;
    font-size: 1.2rem;
    padding: 1.5rem;
    justify-content: center;
  }
  i {
    margin-left: 1rem;
    font-size: 130%;
  }
  &.PaypalButton {
    background-color: #248acf;
    &:hover {
      background-color: #1d79b6;
    }
    &:disabled {
      background-color: #5ab1e855;
      cursor: not-allowed;
      &:hover {
        background-color: #5ab1e855;
      }
    }
  }
  &.CardPayButton {
    background-color: #00ae69;
    &:hover {
      background-color: #03975c;
    }
    &:disabled {
      background-color: #5ab1e855;
      cursor: not-allowed;
      &:hover {
        background-color: #5ab1e855;
      }
    }
  }
  &.GiftButton {
    background-color: #ff895d;
    &:hover {
      background-color: #f77141;
    }
    &:disabled {
      background-color: #ff885d50;
      cursor: not-allowed;
      &:hover {
        background-color: #ff885d50;
      }
    }
  }
}

.OrangeButtonLargeText {
  text-align: center;
  border-radius: 35px;
  background-color: #ff895d;
  color: #ffffff;
  padding: 10px 2rem;
  font-size: 25px;
  font-weight: 400;
  margin: 3rem auto .5rem auto;
  display: table;
  transition: all 0.2s linear;
  @media (max-width: 900px) {
    font-size: 1.2rem;
  }
  &:hover {
    text-decoration: none;
    background-color: #ef6b3a;
    color :inherit;
  }
}