.BirthdayHeader {
  min-height: 50vh;
  padding-top: 20rem;
  padding-bottom: 5rem;
  background-position: top center;
  background-size: cover;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #3336;
    z-index: 1;
  }
  .FundBox {
    position: relative;
    z-index: 5;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 0 1rem;
    h2,
    h4 {
      font-weight: 300;
    }
    .BirthDayBox {
      background-color: #3335;
      padding: 2rem;
      max-width: 480px;
      border-radius: 0.5rem;
      .inputContainer {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        .formInp{
          position: relative;
          width: 32%;
          .srv-validation-message{
            width: 100%;
            right: 0;
            left: 0;
            bottom: 100%;
            top: auto;
            background-color: transparent;
            padding: 0;
            color: #f7ca00;
            &~input{
              background-color: #a919195c;
            }
          }
          
          input {
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }

    .TwoTag span {
      position: absolute;
      left: 15px;
      color: #333;
      top: 13px;
    }

    .inputContainer button {
      margin-bottom: 0;
      margin-left: 0.5rem;
    }

    .TwoTag {
      width: 250px;
      position: relative;
      font-size: 1.2rem;
    }

    .TwoTag span:last-child {
      right: 15px;
      left: auto;
    }

    .TwoTag input {
      padding-left: 2rem !important;
      padding-right: 4rem !important;
      color: #ff895d;
    }
  }
}
