.container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;

  .title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
  }

  .subtitle {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #555;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
    color: #444;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #555;
  }

  ol {
    margin-left: 1.5rem;

    li {
      margin-bottom: 1rem;

      strong {
        color: #222;
      }

      ul {
        margin-left: 1rem;

        li {
          font-size: 0.95rem;
          color: #666;
        }
      }
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    .title {
      font-size: 1.5rem;
    }

    .subtitle {
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
    }

    h2 {
      font-size: 1.25rem;
    }
  }
}

@media (max-width: 480px) {
  .container {
    .title {
      font-size: 1.2rem;
    }

    .subtitle {
      font-size: 0.85rem;
    }

    p {
      font-size: 0.85rem;
    }

    h2 {
      font-size: 1rem;
    }
  }
}
