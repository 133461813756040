.ProgressArea {
  max-width: 1200px;
  margin: 1rem auto 1rem auto;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 1rem;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
  .progressTabLine {
    width: 50%;
    @media (max-width: 900px) {
      width: 100%;
      padding-right: 1.5rem;
      margin-bottom: 2rem;
    }
  }

  // progress bar current circle text
  .ProgressStudents {
    .Progress{
      .line{
        span{
          &.current {
            small::before{
              content: '';
              position: absolute;
              top: 0;
              left: 12px;
              transform: translateY(-11px);
              width: 2px;
              height: 10px;
              background-color: #5ab1e8;
              display: block;
            }
          }
          &.current.pointed::before{
            top: 0;
          }
          &.pointed.current::after{
           display: none;
          }
          &.pointed.current small::before{
            top: 0.5rem;
            left: 1.3rem;
          }
          &.pointed.current small.curentStartText{
            color: #5ab1e8;
          }
          &.current::after{
              content: attr(data-gtext);
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%) translateX(-0.25rem);
              font-size: 0.6rem;
              font-weight: 600;
              color: #ffffff;
            }
        }
        span[data-gtext="O/L"] big{
          transform: translateX(-0.35rem);
        }
        span[data-gtext="A/L"] big{
          transform: translateX(-0.1rem);
        }
      }
      .current-title{
        color: #333;
      }
      .uni-title{
        transform: translateX(-4rem);
      }
    }
  }



  .sponser {
    display: flex;
    flex-direction: column;
    &.need {
      button,
      a {
        background-color: #ff895d;
        span {
          color: #ff895d;
        }
        div {
          justify-content: center;
        }
        &:hover {
          background-color: #fc7848;
        }
      }
    }
    button,
    a {
      background-color: #5ab1e8;
      color: #fff;
      display: flex;
      padding: 0.5rem 2rem 0.5rem 0.5rem;
      border-radius: 52px;
      min-width: 210px;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      border: none;
      &:hover {
        text-decoration: none;
        background-color: #44a3de;
      }
      span {
        width: 50px;
        height: 50px;
        display: block;
        background-color: #fff;
        border-radius: 30px;
        text-align: center;
        line-height: 50px;
        color: #44a3de;
        font-weight: 600;
        font-size: 1.2rem;
      }
      div {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
      }
    }

    > span {
      text-align: center;

      display: block;
    }
  }
}
