.ChatList {
  padding: 1rem 0;
  border-top: solid #f7f7f7 3px;
  margin-top: 1rem;
  max-width: 1000px;
  .chatGroup {
    border: solid 1px #e0e0e0;
    padding: 1rem;
    background-color: #efefef;
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .file {
        color: #5ab1e8;
        transition: all 0.3s linear;
        position: relative;
        overflow: hidden;
        i {
          margin-right: 0.5rem;
        }
        &:hover {
          color: #2173a7;
          cursor: pointer;
        }
        input {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }
      }
    
     .inputContainer{
      width: 100%;
      textarea {
        width: 100%;
        height: 80px;
        resize: none;
        margin-bottom: 1rem;
        border-radius: 3px;
        border-color: #3991c9;
      }
      .srv-validation-message {
        bottom: auto;
        right: 11px;
         top: -8px; 
    }
     }
      button {
        color: #fff;
        background-color: #5ab1e8;
        border-radius: 3px;
        padding: 0.3rem 1.5rem;
        border: none;
        transition: all 0.3s linear;
        &:hover {
          background-color: #3991c9;
        }
      }
    }
  }
}
