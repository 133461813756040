.socialMediaLinks {
  display: flex;
  text-transform: lowercase;
  flex-direction: column;
}
#social {
  display: inline-block;
}

.socialMediaLinks a {
  font-size: 18px;
  margin: 6px 12px 6px 0;
  color: #5ab1e8;
}
.socialMediaLinks a:hover {
  text-decoration: none;
  color: #597f97;
}
.socialMediaLinks a:active {
  text-decoration: none;
  color: #597f97;
}
.socialMediaLinks a:disabled {
  text-decoration: none;
  color: #caced1;
}
.socialMediaLinks h4 {
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .socialMediaLinks {
    padding-left: 0;
  }
}
@media only screen and (max-width: 330px) {
  .socialMediaLinks a {
    font-size: 24pt;
  }
}
