.BottomProfileDetail {
  .center {
    margin: auto;
    width: 100px;
  }
  .flex-column.nav.nav-pills {
    padding-right: 3rem;
    border-right: solid 1px #dcdada;
    @media (max-width: 991px) {
      padding-right: 0;
      border-right: 0;
      margin-bottom: 2rem;
    }
  }
  .nav-item {
    color: #000;
    &.disabled {
      filter: grayscale(1);
    }
    a {
      background-color: #fff !important;
      border-bottom: solid 1px #d0d0d0;
      border-radius: 0;
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        background-color: #f3f3f3 !important;
        color: #333;
      }
      &.active {
        color: #000;
        position: relative;
        border-right: solid 1px #5ab1e8;
        border-bottom-color: #5ab1e8;
        box-shadow: 0px -1px 0px #5ab1e8;
        @media (max-width: 991px) {
          background-color: #5ab1e8 !important;
          color: #fff;
        }
        &::before,
        &::after {
          content: "";
          width: 0;
          height: 0;
          border: solid 13px transparent;
          border-left: solid 11px #5ab1e8;
          position: absolute;
          left: 100%;
          top: calc(50% - 15px);
          z-index: 10;
          @media (max-width: 991px) {
            display: none;
          }
        }
        &::before {
          left: calc(100% - 1px);
          border-left: solid 11px #ffffff;
          z-index: 11;
        }
      }
      span {
        &.active {
          color: #42bb84;
          display: block;
          // color: #fff;
          // padding: 5px 0;
          font-size: 0.8rem;
          // border-radius: 15px;
          // text-align: center;
          text-transform: capitalize;
          border-bottom: solid;
        }
        &.pending {
          @extend .active;
          color: #ff8a5d;
        }
        &.canceled {
          @extend .active;
          color: #eb2208;
        }
        &.renewed {
          @extend .active;
          color: #ebb608;
        }
        &.inactive {
          @extend .active;
          color: #eb2208;
        }
        > span {
          display: inline !important;
          border: none !important;
        }
      }
    }
    img {
      width: 80px;
      height: 80px;
      margin-right: 1rem;
      border: solid 1px #dfdfdf;
      border-radius: 3px;
    }
  }
  .fade {
    transition: opacity 0.3s linear 0.2s;
  }
}
