.Profile {

  // progress bar current circle text
  .ProgressStudents {
    .Progress{
      .line{
        .on{
          background-color: #bbe4ff;
        }
        .inactive::before{
          background-color: #bbe4ff;
        }
        span{
          // .curentStartText::after{
          //   content: 'Sponsorship Period';
          //   font-size: larger;
          //   font-weight: bold;
          //   display: block;
          //   top: -5rem;
          //   left: 10rem;
          //   color: #ff895d;
          //   position: absolute;
          // }

          &.current:before{
            background-color: #ff895d;
          }
          &.current {
            small::before{
              content: '';
              position: absolute;
              top: 0;
              left: 12px;
              transform: translateY(-11px);
              width: 2px;
              height: 10px;
              background-color: #ff895d;
              display: block;
            }
            small.curentStartText{
              color: #ff895d;
            }
          }
          &.current.pointed::before{
            top: 0;
          }
          &.pointed.current::after{
           display: none;
          }
          &.pointed.current small::before{
            top: 0.5rem;
            left: 1.3rem;
          }
          &.pointed.current small.curentStartText{
            color: #ff895d;
          }
          &.current::after{
              content: attr(data-gtext);
              position: absolute;
              top: 50%;
              left: -50%;
              transform: translateY(-50%) translateX(50%);
              width: 1.7rem;
              text-align: center;
              font-size: 0.6rem;
              font-weight: 600;
              color: #ffffff;
            }
        }
        span[data-gtext="O/L"] big{
          transform: translateX(-3rem);
        }
        span[data-gtext="A/L"] big{
          transform: translateX(-2.5rem);
        }
      }
      .current-title{
        color: #333;
      }
      .uni-title{
        transform: translateX(-8rem);
      }
      .period-tittle{
        transform: translateX(-7rem) translateY(-1rem);
        color: #ff895d;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }


  ul.List {
    padding: 1rem 0;
    margin: 0;
    list-style: none;
    line-height: 2.4rem;
    > li {
      display: flex;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        text-align: center;
      }
      > strong {
        width: 270px;
        display: flex;
        justify-content: space-between;
        margin-right: 1rem;
        @media (max-width: 767px) {
          width: 100%;
        }
        &::after {
          content: ":";
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      span.blue {
        color: #5ab1e8;
      }

      span.orange {
        color: #ff8a5d;
      }
      span.yellow {
        color: #ebb608;
      }
      span.red {
        color: #eb2208;
        // margin-left: 1rem;
      }
      span.green {
        color: #42bb84;
        // margin-left: 1rem;
      }
    }
  }
  .BtnCenter {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
  .ProfImg {
    width: 100%;
    border: solid 1px #eaeaea;
  }
}
