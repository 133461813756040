.GitCardHeader {
  min-height: 50vh;
  padding-top: 20rem;
  padding-bottom: 5rem;
  background-position: top center;
  background-size: cover;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #3336;
    z-index: 1;
  }
  .Button{
    font-size: 1rem !important;
  }
  .GiftBox {
    position: relative;
    z-index: 5;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    h2 {
      font-weight: 300;
    }
    a {
      color: #fff;
    }
    .inputContainer {
      display: flex;
      background-color: #3335;
      padding: 0.5rem;
      border-radius: 3rem;
      &.withGroup {
        input[type="text"] {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .css-2b097c-container {
          width: 160px;
          line-height: 40px;
          .css-1wa3eu0-placeholder {
            color: #fff;
          }
          .css-yk16xz-control {
            border-radius: 0;
            background-color: #007fb5;
            border-color: #007fb5;
            .css-1uccc91-singleValue {
              color: #fff;
            }
          }
        }
        button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: 0;
          font-size: 1.3rem;
          padding-right: 2rem;
        }
      }
    }

    .TwoTag span {
      position: absolute;
      left: 15px;
      color: #333;
      top: 13px;
    }

    .inputContainer button {
      margin-bottom: 0;
      margin-left: 0.5rem;
    }

    .TwoTag {
      width: 250px;
      position: relative;
      font-size: 1.2rem;
    }

    .TwoTag span:last-child {
      right: 15px;
      left: auto;
    }

    .TwoTag input {
      padding-left: 2rem !important;
      padding-right: 4rem !important;
      color: #ff895d;
    }
  }
}
