.MainSection {
  position: relative;
  display: hidden;
}
.ASCroll {
  margin: 0;
  padding: 0;
  list-style: none;
}
.StikyTab {
  padding: 0;
  margin: -7px 0 0 0;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  &.sticky {
    position: sticky;
    top: -5px;
    background-color: #fff;
    width: 100%;
    // box-shadow: 0 0 3px;
    z-index: 10;
  }
  @media (max-width: 768px) {
    display: none;
  }
  li {
    border-right: solid 1px #333;
    padding: 0 1rem;
    &:last-child {
      border: none;
    }
    a.active.on {
       
        border-bottom: solid 2px #ff895d;
        padding: 5px 0;
      
    }
    a {
      color: #333;
      &:hover {
        text-decoration: none;
        color: #ff895d;
      }
    }
  }
}
.SecondSection {
  font-size: 22px;
  font-weight: 300;
  color: #4e4e4e;
  padding: 3rem 0;
  text-align: center;
  .wrapper {
    margin: auto;
    max-width: 800px;
    a {
      color: #5ab1e8;
    }
  }
  .blue {
    color: #1b435d;
    font-weight: 300;
    font-size: 44px;
    margin-top: 40px;
  }
  strong {
    font-weight: 500;
  }
  .meetAll {
    border: 2px;
    border-style: solid;
    border-radius: 30px;
    background-color: #ffffff;
    border-color: #ff895d;
    font-weight: 600;
    padding: 10px 30px;
    text-transform: none;
    font-size: 25px;
    display: inline-block;
    color: #ff895d !important;
    margin-top: 30px;
    &:hover {
      background-color: #ff895d55;
      text-decoration: none;
    }
  }
  .width-100 {
    width: 220px;
    margin: auto;
    small {
      font-size: 14px;
      font-weight: 400;
      strong {
        font-weight: 700;
      }
    }
  }
}
