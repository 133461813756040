.container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 1rem;

  h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }

  hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 1rem 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #444;
  }

  ol {
    margin-left: 1.5rem;
    padding-left: 0.5rem;

    li {
      font-size: 1rem;
      margin-bottom: 1rem;

      strong {
        display: block;
        margin-bottom: 0.5rem;
        color: #222;
      }

      ul {
        margin-left: 1.5rem;
        padding-left: 1rem;
        list-style-type: disc; /* This adds bullet points */
        margin-top: 0.5rem;

        li {
          font-size: 0.95rem;
          color: #666;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  strong {
    color: #222;
    font-weight: bold;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    h1 {
      font-size: 1.5rem;
    }

    p,
    li {
      font-size: 0.9rem;
    }

    h2 {
      font-size: 1.25rem;
    }
  }
}

@media (max-width: 480px) {
  .container {
    h1 {
      font-size: 1.2rem;
    }

    p,
    li {
      font-size: 0.85rem;
    }

    h2 {
      font-size: 1rem;
    }
  }
}
