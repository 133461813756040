.PasswordReset {
  max-width: 400px;
  width: 100%;
  margin: auto;
  h2 {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    margin-top: 2rem;
  }
  .form {
    margin: 2rem 0;
    padding: 3rem;
    background-color: #5ab1e833;
    border-radius: 1rem;
  }
}
