.TopProfileDetail {
  display: flex;
  padding: 2rem;
  h1,
  h2,
  h3,
  h4 {
    color: #5ab1e8;
  }
  .colorBlue {
    color: #5ab1e8;
  }
  .darkLight {
    color: #1b435d;
    font-weight: 300;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
  .Left {
    display: flex;
    align-items: center;
    width: 40%;
    border-right: solid 1px #f1f1f1;
    @media (max-width: 991px) {
      border-right: none;
      border-bottom: solid 1px #f1f1f1;
      padding-bottom: 1rem;
      width: 100%;
      margin-bottom: 1rem;
    }
    img {
      width: 120px;
      margin-right: 1rem;
      height: 120px;
      border: solid 1px #efefef;
      @media (max-width: 767px) {
        width: 90px;
        height: 90px;
      }
    }
  }
  .Center {
    padding-left: 2rem;
    width: 60%;

    .details-msg{
      font-weight: 300;
      font-size: 18px;
      .colored-text{
        color: #5ab1e8;
        font-weight: 400;
      }
    }

    @media (max-width: 991px) {
      padding-left: 0;
      width: 100%;
    }
  }
  .Right{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;

    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0rem;
      width: 100%;
    }
  }
  .Detail {
    padding: 0 1rem;
    h4 {
      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }
    .LinkLineButton.sm {
      margin: 0.5rem 0;
    }
    a {
      color: #999999;
    }
  }

  .hover-bold {
    cursor: pointer;
    color: #5ab1e8;
    transition: font-weight 0.3s ease;
  }
  
  .hover-bold:hover {
    font-weight: bold;
  }
}
