.Payment {
  margin-bottom: 2rem;
  // box-shadow: 0 0 3px 1px #333;
  ul {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
    line-height: 2rem;
    li {
      display: flex;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        text-align: center;
      }
      strong {
        margin-right: 1rem;
        width: 200px;
        display: flex;
        @media (max-width: 767px) {
          width: 100%;
          justify-content: space-between;
          width: 100%;
        }
        &::after {
          content: ":";
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      span.Blue {
        color: #5ab1e8;
        margin-right: 0.5rem;
      }
      span.orange {
        color: #ff8a5d;
      }
      span.Red {
        color: #ff8a5d;
      }
    }
  }
  .ScrollTable{
    box-shadow: 0px 4px 8px -8px #333;
    td, th {
      padding: 0.3rem 1rem;
  }
  }
  h4 {
    // color: #ffffff;
    font-size: 1rem;
    margin-bottom: 0;
    text-transform: uppercase;
    
    display: flex;
    width: auto;
    // padding: 5px .5rem;
    
    span{
      background-color: #f9f6f6;
      display: block;
      padding: .5rem 1rem;
      &:first-child{
        background-color: #efefef;
      }
    }
  }
}
