.BlueImpactArea {
  padding: 60px;
  background-color: #1b435d;
  color: #fff;
  .handrend {
    font-size: 6rem;
    font-weight: 600;
    color: #ff895d;
    text-align: center;
    line-height: 140px;
    font-family: "Fjalla One", sans-serif;
  }
  h2 {
    color: #d5eeff;
    font-size: 24px;
  }
}
