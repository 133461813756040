.socialBtn {
  min-width: 100%;
  > div {
    min-width: 100%;
    button {
      color: #424242 !important;
      background-color: #fff !important;
      transition: all 0.2s;
      box-shadow: 1px 1px 5px #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      &.fb {
        color: #fff !important;
        background-color: #1877f2 !important;
        i{
          font-size: 22px;
          margin-right: .5rem;
        }
      }
      &.google{
        
        span{
          background-image: url("../../../assets/google.png");
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: .5rem;
          background-size: contain;
        }
      }
      &:hover {
        opacity: 0.8;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}
