.CreateCampaign {
  max-width: 500px;
  margin: auto;
  > h2 {
    font-size: 3rem;
    font-weight: 300;
    text-align: center;
    margin-top: 2rem;
  }
  > h4 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 2rem;
  }
}
