.MultiStudenModal {
    .btnContainer {
      display: flex;
      button {
        background-color: transparent;
        border: none;
        color: #42ee2b;
      }
    }
    .ScrollTable {
      box-shadow: 0px 4px 8px -8px #333;
      td,
      th {
        padding: 0.3rem 1rem;
      }
    }
    .table-responsive {
      overflow-x: auto;
      white-space: nowrap;
    }
    .student-img {
      width: 40px;
      border-radius: 50%;
    }
    .status-text {
      font-weight: bold;
    }
  
    @media (max-width: 768px) {
      .table th,
      .table td {
        font-size: 12px;
        padding: 8px;
      }
      .student-img {
        width: 30px;
      }
      .btn-rounded {
        font-size: 10px;
        padding: 3px 8px;
      }
    }
    .ButtonPanel {
      display: flex;
      button {
        background-color: transparent;
        border: none;
        color: #5ab1e8;
      }
      .file {
        color: #5ab1e8;
        transition: all 0.3s linear;
        position: relative;
        overflow: hidden;
        // padding-right: 1rem;
        // border-right: solid 1px #ccc;
        margin-right: 1rem;
        i {
          margin-right: 0.5rem;
        }
        &:hover {
          color: #2173a7;
          cursor: pointer;
        }
        input {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }
      }
    }
    img {
      max-width: 200px;
      margin: auto;
      width: 100%;
    }
    .btn-rounded {
      border-radius: 20px;
    }
    button.Check {
      background-color: transparent;
      border: navajowhite;
      margin: auto;
      display: block;
      color: #a9a9a9;
      display: flex;
      align-items: center;
      &:focus {
        outline: none;
      }
      &.active {
        i {
          &::before {
            opacity: 1;
            color: #00af6b;
          }
        }
      }
      i {
        border: solid 1px #ccc;
        border-radius: 16px;
        width: 20px;
        height: 20px;
        font-size: 1.5rem !important;
        margin-right: 10px;
        position: relative;
        &::before {
          opacity: 0;
          position: relative;
          top: -2px;
          left: -2px;
        }
      }
    }
    .ButtonLeft {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      border-top: solid 1px #ccc;
      padding: 1rem;
    }
    .LineOn {
      border-left: solid 1px #ccc;
    }
    .ProfileImg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        margin-bottom: 2rem;
      }
    }
  }
  