body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.notFound {
  padding: 1rem 2rem;
  background-color: #ffd34180;
  margin: 2rem auto;
  text-align: center;
  border-radius: 5px;
  color: #806200 !important;
  border: solid 1px #ffd341;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.Error {
  padding: 1rem 2rem;
  background-color: #ff6d4180;
  margin: 2rem auto;
  text-align: center;
  border-radius: 5px;
  color: #ec4300 !important;
  border: solid 1px #ff7541;
  text-transform: uppercase;
  font-size: 1.2rem;
}
