.QuickPay {
    .modal-dialog {
      margin: 5rem auto 2rem auto;
    }
    .modal-header {
      position: relative;
    }
    .TitleContent {
      text-align: center;
      margin: 1rem 0 2rem 0;
      width: 100%;
      padding-left: 2rem;
      h4 {
        color: #ccc;
      }
      small {
        color: #5ab1e8;
        font-size: 1rem;
        display: block;
      }
      img {
        position: absolute;
        top: -50px;
        border-radius: 100%;
        box-shadow: 0 0 3px 1px #5ab1e8;
        background-color: #fff;
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
      }
    }
    button {
      font-size: 2rem;
    }
    label.checkLabel {
      display: table;
  
      max-width: 150px;
      margin: 1rem auto 1rem auto;
      text-align: center;
      color: #777;
      position: relative;
      padding-left: 1.5rem;
      cursor: pointer;
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: solid 2px #777;
        display: inline-block;
        margin-right: 0.5rem;
        position: absolute;
        left: 0;
  
        top: 2px;
      }
      &.recurring {
        &:before {
          border: solid 2px #4b950d;
        }
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 20px;
          background-color: #4b950d;
          display: inline-block;
          margin-right: 0.5rem;
          position: absolute;
          left: 5px;
          top: 7px;
        }
        color: #4b950d;
      }
    }
    .line {
      padding: 1.5rem 0 0 0;
      margin-bottom: 3rem;
    }
    .hr {
      border-top: solid 1px #e1e1e1;
      margin-bottom: 1.5rem;
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
  