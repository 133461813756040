.ProfileTab {
  .nav-item a {
    color: #333;
    background-color: #ffffff;
  }
  .nav-item a.active {
    border-width: 0;
    box-shadow: none;
    border-bottom-width: 3px;
    border-color: #ff8a5d;
    &::before,
    &::after {
      display: none;
    }
  }
  .download {
    color: #8fcef5;
    font-weight: 600;
    button {
      color: #333;
      font-weight: 400;
      background-color: transparent;
      border: none;
      &:hover {
        color: #000;
      }
    }
  }
  .tab-content > .active {
    padding: 2rem 1rem;
    @media (max-width: 767px) {
      padding: 2rem 0;
    }
    h2 {
      color: #1b435d;
      font-size: 1.5rem;
    }
  }
}
