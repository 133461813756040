.GiftcardPay {
  .modal-dialog {
    margin: 5rem auto 2rem auto;
  }
  .modal-header {
    position: relative;
  }
  .TitleContent {
    text-align: center;
    margin: 3rem 0 2rem 0;
    width: 100%;
    padding-left: 2rem;
    h4 {
      color: #ccc;
    }
    h3 {
      margin: 0;
    }
    small {
      color: #5ab1e8;
      font-size: 16px;
    }
    img {
      position: absolute;
      top: -50px;
      border-radius: 100%;
      box-shadow: 0 0 3px 1px #5ab1e8;
      background-color: #fff;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
    }
  }
  button {
    font-size: 2rem;
  }
  label {
    display: table;

    max-width: 150px;
    margin: 1rem auto 1rem auto;
    text-align: center;
    color: #777;
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: solid 2px #777;
      display: inline-block;
      margin-right: 0.5rem;
      position: absolute;
      left: 0;

      top: 2px;
    }
    &.recurring {
      &:before {
        border: solid 2px #4b950d;
      }
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 20px;
        background-color: #4b950d;
        display: inline-block;
        margin-right: 0.5rem;
        position: absolute;
        left: 5px;
        top: 7px;
      }
      color: #4b950d;
    }
  }
  .line {
    padding: 1.5rem 0 0 0;
    margin-bottom: 3rem;
  }
  .loading {
    i {
      color: #4b950d;
    }
  }
  .success {
    i {
      color: #4b950d;
    }
    & ~ input {
      background: #4b950e4d !important;
    }
  }
  .fail {
    i {
      color: #ca4646;
    }
    & ~ input {
      background: #ca46464d !important;
    }
  }
  .cardAmount span {
    font-weight: 600;
  }

  .cardAmount {
    text-align: center;
  }

  .giftcardDetail {
    text-transform: uppercase;
    border: #53af6a solid 1px;
    color: #53af6a;
    padding: 0.5rem 1rem;
    display: table;
    margin: 0 auto 1rem auto;
    border-radius: 2rem;
  }

  .giftCardBalanceCard {
    text-transform: uppercase;
    border: #53af6a solid 1px;
    color: #53af6a;
    padding: 0.5rem 1rem;
    display: table;
    margin: 1rem auto 1rem auto;
    border-radius: 2rem;
  }

  .giftCardBalanceNotSufficient {
    text-transform: uppercase;
    border: #ca464667 solid 1px;
    color: #ca4646;
    padding: 0.5rem 1rem;
    display: table;
    margin: 1rem auto 1rem auto;
    border-radius: 2rem;
    span {
      color: #59b1e8;
    }
  }

  .need {
    margin-bottom: 1rem;
    color: #59b1e8;
    text-transform: uppercase;
  }

  .toStudent span {
    color: #59b1e8;
  }

  .toStudent {
    color: #888;
  }
  .center {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .donated {
    color: #ca4646;
    background-color: #ca464667;
    padding: 0.5rem 1.5rem;
    display: table;
    margin: 0 auto 1rem auto;
    border-radius: 2rem;
  }
  .info {
    i {
      color: #ecb70a;
    }
  }
  .inputContainer input {
    text-align: center;
  }
}
