.StudentNewsList {
  left: -2rem;
  position: absolute;
  height: calc(100% - 80px);
  @media (max-width: 767px) {
    position: relative;
    max-height: 500px;
    left: -1rem;
  }
  .newsItem {
    position: relative;
    display: flex;
    flex-direction: column;
    border-left: solid 3px #7be7ff;
    padding-left: 4rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    margin-left: 3rem;
    .dateTag {
      position: absolute;
      width: 80px;
      height: 80px;
      background-color: #cecece;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      left: -40px;
      top: 1rem;
      span {
        font-size: 0.7rem;
        text-transform: uppercase;
      }
      h4 {
        font-size: 1.7rem;
        font-weight: 300;
        line-height: 1.7rem;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        &::after,
        &::before {
          content: "";
          width: 5px;
          height: 1px;
          background-color: #3c3738;
          display: block;
          margin: 0 0.3rem;
        }
      }
    }

    h2 {
      color: #3c3738;
      margin-top: 20px;
      margin-bottom: 25px;
    }

    .newsItemContent {
      .img {
        float: left;
        margin: 0 1rem 1rem 0;
        img {
          max-width: 200px;
          width: 100%;
        }
      }
      .newsBody {
      }
    }
  }
}
