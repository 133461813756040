.Sponsorship {
  border-bottom: solid 1px #ededed;
  padding-bottom: 2rem;
  &:last-child{
    border: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 2rem 0 0 0;
    line-height: 2rem;
    li {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        text-align: center;
      }
      i {
        margin-right: 0.2rem;
      }
      strong {
        margin-right: 1rem;
        width: 300px;
        display: flex;
        @media (max-width: 767px) {
          width: 100%;
        }
        justify-content: space-between;
        &::after {
          content: ":";
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      span.Green {
        color: #42bb84;
      }
      span.Orange {
        color: #ff8a5d;
        margin-right: 0.5rem;
      }
      span.yellow {
        color: #ebb608;
      }
      span.Red {
        color: #eb2208;
      }
    }
  }
  .cancelLink{
    margin-top: 1rem;
  }
}
