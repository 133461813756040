.Page404 {
  text-align: center;
  padding: 4rem 1rem;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  flex-direction: column;
  img {
    max-width: 200px;
  }
}
