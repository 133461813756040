
  .forgotSign {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    >div{
      display: flex;
      align-items: center;
    }
    button{
      font-size: 1rem;
    }
  }

