.CampaignPayment {
  .modal-dialog {
    margin: 5rem auto 2rem auto;
  }
  .modal-header {
    position: relative;
  }
  .TitleContent {
    text-align: center;
    margin: 3rem 0 2rem 0;
    width: 100%;
    padding-left: 2rem;
    h4 {
      color: #ccc;
    }
    h2{
      margin-bottom: 0;
    }
    small {
      color: #5ab1e8;
      font-size: 16px;
    }
    img {
      position: absolute;
      top: -50px;
      border-radius: 100%;
      box-shadow: 0 0 3px 1px #5ab1e8;
      background-color: #fff;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
    }
  }
  
  .form-control{
    border-color: #5ab1e8;
    border-radius: 2rem;
  }
  .inputContainer.amountInput{
    width: 100%;
    i{
      width: 0.8rem;
      left: 35px;
      z-index: 1;
    }
    input {
      padding-left: 43px;
    }
  }
  .groupInputs{
    display: flex;
    flex-wrap: wrap;
  
  }
.buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  button{
    // width: 48%;
    justify-content: center;

  }
}
  .line {
    padding: 1.5rem 0 0 0;
    margin-bottom: 3rem;
  }
  .inputContainer .srv-validation-message {
    top: -10px;
    right: 11px;
  }
}
.switch{
  overflow: hidden;
  border-radius: 2rem;
  display: table;
  margin: 0 auto 1rem auto;
  border: solid 1px #ccc;
  transition: all 0.3s linear;
  i{
    width: 100px;
    text-align: center;
    font-size: 2rem;
    padding: 0.5rem 1rem;
    background-color: #fcfcfc;
    transition: all 0.3s linear;
   &.active{
     background-color: #1472ac;
     color:#fff;
   }
  }
}
