.copy-btn{
    display: block;
    background-color: transparent;
    color: #fff;
    border: none;
    margin: 0 0.5rem;
    padding: 0 !important;
    transition: all 0.2s linear;
    
    svg {
      width: 1.6rem;
      
      path{
        fill: #fff;
        transition: all 0.2s linear;
      }
    }

    &:hover {
      svg{
        path{
          fill: #5ab1e8;
        }
      }
      text-decoration: none;
    }

}