.PasswordReset {
  max-width: 600px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 2rem;
  }
  small {
    color: #5ab1e8;
    font-size: 1rem;
    display: block;
  }
  .form {
    max-width: 600px;
    margin: 2rem 0;
    padding: 3rem;
    background-color: #5ab1e833;
    border-radius: 1rem;
  }
}
