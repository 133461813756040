.Student {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  img {
    width: 100%;
    max-width: 200px;
    margin: 1rem auto;
    border: solid 1px #efefef;
  }

  h1 {
    color: #ff895d;
    font-size: 1.4rem;
  }

  h2 {
    color: #5ab1e8;
    font-size: 1.2rem;
  }

  .SponsoredBy h1 {
    font-size: 1.2rem;
  }

  .SponsoredBy {
    display: flex;
    align-items: center;
  }

  .SponsoredBy a {
    display: flex;
    align-items: center;
  }

  .SponsoredBy a img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .SponsoredBy i {
    font-size: 2rem;
    margin-left: auto;
    color: #7dd565;
  }

  .SponsoredBy {
    margin: auto;
  }
}
