.Video {
  position: relative;
  background-position: center center;
  // background-size: 0;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  box-shadow: 0px -10px 0px 0px #5ab1e8 inset;
  @media (max-width: 767px) {
    background-size: auto 100%;
    min-height: 100vh;
    video {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(100% - 5px);
    background-color: #0005;
    top: 0;
    left: 0;
  }
}
