.Banner {
  height: calc(100vh - 100px);
  max-height: 800px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  background-size: cover;

  .Wrapper {
    max-width: 1024px;
    margin: auto;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    // background-color: #0004;
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
  }
  .profileBox {
    margin: 2rem;
    color: #fff;
    z-index: 11;
    display: flex;
    .orangeButton {
      padding: 0.3rem 1rem;
    }
    .profileImg {
      width: 130px;
      height: 130px;
      background-color: #eafaff;
      background-image: url("../../assets/photo.png");
      margin-right: 1rem;
      border-radius: 3px;
      background-size: cover;
    }
    .profDetail {
      .name {
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
      .userName {
        font-size: 1.4rem;
        font-weight: 300;
        text-transform: capitalize;
      }
      .buttonLine {
        display: flex;
        align-items: center;
        button {
          border-radius: 1.5rem;
          padding: 0.3rem 1rem;
          font-size: 1rem !important;
        }
        .editBtn {
          border-radius: 1.5rem;
          padding: 0.3rem 1rem;
          border: solid 1px #fff;
          font-size: 1rem;
          transition: all 0.2s linear;
          &:hover {
            background-color: #5ab1e8;
            color: #fff;
            border-color: #5ab1e8;
          }
        }
        a {
          display: block;
          color: #fff;
          font-size: 1.7rem;
          margin: 0 0.5rem;
          transition: all 0.2s linear;
          
          svg {
            width: 1.4rem;
            height: 1.4rem;
            path{
              fill: #fff;
              transition: all 0.2s linear;
            }
          }

          &:hover {
            svg {
              path{
                fill: #5ab1e8;
              }
            }
            color: #5ab1e8;
            text-decoration: none;
          }

        }
      }
    }
  }
}
.PaymentBox {
  background-color: #eafaff;
  color: #4e4e4e;
  padding: 2rem 1rem;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  .Price {
    font-size: 2rem;
    color: #5ab1e8;
    strong {
      font-size: 2.5rem;
    }
    small {
      font-weight: 300;
    }
  }
  .iconLine {
    padding: 0;
    font-size: 1.1rem;
    i {
      color: #5ab1e8;
      margin-right: 0.5rem;
    }
    strong {
      margin-right: 0.2rem;
    }
  }
  .targetLine {
    padding: 1rem 0.5rem;
    background-color: #bfe4f8;
    margin-bottom: 1rem;
    border-radius: 3px;
    .percentage {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }
    .line {
      height: 10px;
      width: 100%;
      background-color: #fff;
      margin-top: 0.5rem;
      border-radius: 16px;
      overflow: hidden;
      span {
        width: 1%;
        display: block;
        background: #00af6b;
        height: 10px;
      }
    }
  }
  #pay-donate-btn {
    padding: 0.6rem 2rem;
  }
}
.MessageContainer {
  .Updates {
    background-color: #eafaff;
    padding: 1rem;
    border-radius: 3px;
    .updateMesages {
      display: flex;
      flex-direction: column;
      textarea {
        width: 100%;
        resize: none;
        height: 100px;
        border-radius: 3px;
        border: solid 1px #efefef;
        padding: 0.5rem;
      }
      .line {
        display: flex;
        align-items: center;

        .sendEmail {
          font-size: 0.8rem;
          display: flex;
          transition: all 0.2s linear;

          &:hover {
            color: #999;
            cursor: pointer;
          }

          &.active {
            span {
              transition: all 0.2s linear;
              border-color: #1bc74f;

              &:after {
                background-color: #1bc74f;
                transform: scale(1);
              }
            }
          }
          span {
            position: relative;
            margin-right: 0.3rem;
            border-radius: 1rem;
            border: solid 2px #5ab1e8;
            display: inline-block;
            width: 18px;
            height: 18px;
            &:after {
              content: "";
              transition: all 0.2s linear;
              display: block;
              background-color: #fff;
              width: 10px;
              height: 10px;
              position: absolute;
              border-radius: 1rem;
              top: 2px;
              left: 2px;
              transform: scale(0);
            }
          }
        }
        button {
          background-color: #5ab1e8;
          border-radius: 1rem;
          padding: 0.3rem 1rem;
          border: none;
          color: #fff;
          margin-left: auto;
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-size: 1rem;
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        background-color: #fff;
        border: solid 1px #5ab1e8;
        padding: 1rem;
        margin-bottom: 2rem;
        border-radius: 3px;
        font-size: 0.9rem;
        position: relative;
        p {
          margin-bottom: 0;
        }
        span {
          white-space: nowrap;
          color: #5ab1e8;
          margin-left: 1rem;
          position: absolute;
          top: 100%;
          right: 0;
          line-height: 30px;
        }
      }
    }
  }
}

.CampaignList {
  h1 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .imageTag {
    display: block;
    width: 100%;
    height: 200px;
    background-size: cover;
    margin-bottom: 2rem;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background-color: rgba(0, 0, 0, 0.178);
      width: 100%;
      height: 100%;
      transition: all 0.2s linear;
    }
    div {
      text-align: center;
      width: 100%;
      position: relative;
      z-index: 10;
      color: #fff;
      text-decoration: none;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      padding: 0 1rem;
      transition: all 0.2s linear;
    }
    &:hover {
      text-decoration: none;
      &::after {
        background-color: rgba(0, 0, 0, 0.678);
      }
    }
  }
}
.whyEducation {
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
}
.support {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
  color: #252525;
  h2 {
    font-weight: 300;
  }
  a {
    min-width: 200px;
  }
}
.lightBlueBackground{
  background-color: #eafaff;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}
.avatars{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  justify-items: center;
  margin: 1rem 0;
  span{
    position: relative;
    width: 40px;
    height: 40px;
    background-size: cover;
    border-radius: 30px;
    background-color: rgb(199, 199, 199);
    background-image: url("../../assets/user.png");
    border: solid 3px #dadada;
    background-position: center center;
    &:hover{
      small{display: block;}
    }
    small{
      display: none;
      position: absolute;
      background-color: #000;
      color: #fff;
      white-space:nowrap;
      left: 100%;
      border-radius: 30px;
      top: 5px;
      padding: 5px 1rem;
      z-index: 10;
    }
  }
}