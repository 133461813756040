.TopSlider {
  color: #fff;
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p{
    margin: .25rem 0;
  }
  .carousel {
    .slide {
      background: transparent;
    }
  }
  @media (max-width: 1100px) {
    top: 25vh;
  }
  @media (max-width: 1100px) {
    top: 35vh;
  }
  @media (max-width: 768px) {
    top: 10vh;
  }
  h1 {
    margin: 0 auto .5rem auto;
    font-size: 4rem;
    font-weight: 600;

  }
  h3 {
    background-color: #3333339e;
    display: table;
    margin: 0 auto 3rem auto;
    padding: .5rem 1.5rem;
    font-size: 1.5rem;
    border-radius: .3rem;
    color: #ffffffca;
    font-weight: 400;
  }
  .SponsorLink1 {
    text-align: center;
    border-radius: 35px;
    background-color: #ff895d;
    color: #ffffff;
    padding: 10px 2rem;
    font-size: 25px;
    font-weight: 400;
    margin: 3rem auto .5rem auto;
    display: table;
    @media (max-width: 900px) {
      font-size: 1.2rem;
    }
    &:hover {
      text-decoration: none;
      background-color: #ef6b3a;
      color :inherit;
    }
  }
  .orangeButton {
    font-size: 1.5rem;
    padding: 10px 34px;
    margin-top: 2rem;
  }
  .Detail {
    // font-weight: 200;
    font-size: 1rem;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .MainSlider {
    max-width: 100vh;
    @media (max-width: 1024px) {
      max-width: 60vh;
    }
    @media (max-width: 498px) {
      max-width: 45vh;
    }
    @media (max-width: 400px) {
      max-width: 40vh;
    }
  }

  .Item {
    h1 {
      font-size: 48px; // Default size
    }
    h4 {
      font-size: 1.5rem; // Default size
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 30px; // Smaller size for mobile
      }
      h4 {
        font-size: 1rem; // Smaller size for mobile
      }
    }
  }
}
